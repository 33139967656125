import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';
import GroupCaption from '../../pages/group-caption/GroupCaption';

export default function CustomerDoctorEditForm() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  const [doctorToken, setDoctorToken] = useState("");

  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [formData, setformData] = useState({
    name: "",
    linkedCustomerToken: "",
    description0: "",
    address: "",
    contactPerson: "",
    email: "",
    phone: "",
    loginEmail: "",
    status: "",
  });
  const formDataRef = useRef(null);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "success",
    message: "",
  });

  const [linkedCustomerItems, setLinkedCustomerItems] = useState([]);

  const groupCaptionNamedRender = (iconName) => {
    const groupCaptionRender = (data) => (
      <GroupCaption iconName={iconName} {...data} />
    );
    return groupCaptionRender;
  };

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("dsadasda", formData)
    // console.log('aaaaa', doctorToken)
    if (!validateEmail(formData.email)) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: "error",
        message: "Email format error",
      });
    } else if (!validateEmail(formData.loginEmail)) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: "error",
        message: "Login Email format error",
      });
    } else {
      setLoading(true);

      try {
        const response = await axios
          .post(
            backendlink + "customer/editdoctor",
            {
              usertoken: user.userToken,
              doctortoken: doctorToken,
              name: formData.name,
              description0: formData.description0,
              companyaddress: formData.address,
              contactperson: formData.contactPerson,
              email: formData.email,
              phone: formData.phone,
              username: formData.loginEmail,
              isEnabled: formData.status,
            },
            {
              headers: {
                token: user.token,
              },
            }
          )
          .then((res) => {
            setLoading(false);

            if (res.data.errorno == 0) {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "success",
                // message: 'New partner has been added',
                message: "Doctor updated",
              });

              // formData.current.instance.clear();
            } else {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "error",
                message: res.data.errmessage,
              });
            }
          });
      } catch (error) {
        setLoading(false);

        // console.log(error);
        alert(error);
      }
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    // console.log(location.state)

    if (location.state?.doctorToken == null) {
      navigate("/");
    } else {
      setDoctorToken(location.state.doctorToken);

      const fetchData = async () => {
        try {
          const response = await axios.post(
            backendlink + "customer/getdoctor",
            {
              usertoken: user.userToken,
              doctortoken: location.state.doctorToken,
            },
            {
              headers: {
                token: user.token,
              },
            }
          );

          if (response.data.errorno == 0) {
            // console.log(response.data.result)

            const outputArray = response.data.result.map((item) => {
              // console.log(item)

              setformData({
                name: item.name,
                linkedCustomerToken: item.parentToken,
                linkedCustomerName: item.linkedcustomer,
                description0: item.description0,
                address: item.companyaddress,
                contactPerson: item.contactperson,
                email: item.email,
                phone: item.phone,
                loginEmail: item.username,
                status: item.isEnabled,
              });
            });
          } else {
            alert(response.data.errmessage);
          }
        } catch (error) {
          // console.log(error);
          alert(error);
        }
      };

      fetchData();
    }
  }, [user]);

  const readonlyptions = { readOnly: "true" };

  var statusItems = [
    { id: true, text: "Active" },
    { id: false, text: "Inactive" },
  ];

  return (
    <form onSubmit={onSubmit}>
      <Form
        formData={formData}
        ref={formDataRef}
        disabled={false}
        colCount={4}
        style={{ paddingTop: "20px" }}
      >
        <GroupItem
          iconName="user"
          captionRender={groupCaptionNamedRender("user")}
          caption="Personal Data"
          colSpan={4}
        >
          <GroupItem colCount={2}>
            <Item dataField={"name"} editorType={"dxTextBox"}>
              <Label text="Doctor Name" />
            </Item>

            {/* <Item
                    dataField={'linkedCustomerToken'}
                    editorType={'dxSelectBox'}
                    editorOptions= {{
                        items: linkedCustomerItems,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    }}
                >
                    <Label text='Linked Organization' />
                </Item> */}

            <Item dataField={"description0"} editorType={"dxTextBox"}>
              <Label text="Description" />
            </Item>

            <Item dataField={"address"} editorType={"dxTextBox"}>
              <Label text="Address" />
            </Item>

            <Item
              dataField={"status"}
              editorType={"dxSelectBox"}
              editorOptions={{
                items: statusItems,
                displayExpr: "text",
                valueExpr: "id",
              }}
            >
              <Label text="Status" />
            </Item>
          </GroupItem>
        </GroupItem>

        <GroupItem
          captionRender={groupCaptionNamedRender("card")}
          caption="Login Data"
          colSpan={2}
        >
          <GroupItem colCount={1}>
            <Item dataField={"loginEmail"} editorType={"dxTextBox"}>
              <Label text="Login Email" />
            </Item>
          </GroupItem>
        </GroupItem>

        <GroupItem
          captionRender={groupCaptionNamedRender("home")}
          caption="Emergency Contact Information"
          colSpan={2}
        >
          <Item dataField={"contactPerson"} editorType={"dxTextBox"}>
            <Label text="Contact Person" />
          </Item>
          
          <GroupItem colCount={2}>
            <Item colSpan={1} dataField={"phone"} editorType={"dxTextBox"}>
              <Label text="Contact Number" />
            </Item>

            <Item colSpan={1} dataField={"email"} editorType={"dxTextBox"}>
              <Label text="Contact Email" />
            </Item>
          </GroupItem>
        </GroupItem>

        <ButtonItem colSpan={4} cssClass="full-width-button">
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Save"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>

      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={2000}
      />
    </form>
  );
}
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './CustomerDoctorListDatagrid.css';

export default function CustomerDoctorListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [formData, setformData] = useState({ startDate: '', endDate: '' });
    const formDataRef = useRef(null)

    const [loadingExport, setLoadingExport] = useState(false);

    function handleRowClick(e) {
        const doctortoken = e.data.doctorToken;
        navigate('/customer-doctor-info', { state: { doctorToken : doctortoken } });
    }

    useEffect(() => {
        // console.log(user.token)
        // console.log(user.userToken)

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'customer/getalldoctors',
                {
                    customertoken: user.userToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    console.log(response.data.result)
                    const outputArray = response.data.result[0].map(item => {
                        var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                        return { doctorName: item.name, doctorID: item.id, status: statusString, linkedCustomer: item.linkedcustomer, phone: item.phone, email: item.email, doctorToken: item.userToken, description: item.description0 };
                    });
                    setUsers(outputArray)
                }
                else if (response.data.errorno == 330) {
                    setUsers([])
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

        var dateStartdate = new Date(Date.now() - 86400000)
        var dateEnddate = new Date()

        setformData({ startDate: dateStartdate, endDate: dateEnddate })
    }, [user]);

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    return (
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClick}>
                <Column dataField='doctorID' caption='Doctor Account ID'  alignment='left' />
                <Column dataField='doctorName' caption='Doctor Name' />
                <Column dataField='description' caption='Description' />
                <Column dataField='phone' caption='Phone Number' />
                <Column dataField='email' caption='Contact Email' />
                <Column dataField='linkedCustomer' caption='Linked Organization' />


                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>

        </React.Fragment>
    )

}

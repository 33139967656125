import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';

export default function PartnerCustomerInfoList() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [customerToken, setCustomerToken] = useState("")

    const location = useLocation();

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ id: '', companyname: '', companyaddress: '', contactperson: '', email: '', phone: '', loginEmail: '' });
    const formDataRef = useRef(null)

    function pressEdit() {
        navigate('/partner-customer-edit', { state: { customerToken: customerToken } });
    }

    function pressChangePassword() {
        navigate('/partner-customer-changepassword', { state: { customerToken: customerToken } });
    }

    useEffect(() => {
        // console.log(location.state)
        
        if (location.state?.customerToken == null) {
            navigate('/');
        } else {
            setCustomerToken(location.state.customerToken);
            // console.log(location.state.customerToken)
            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'partner/getcustomer',
                    {
                        usertoken: user.userToken,
                        customertoken: location.state.customerToken
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });

                    if (response.data.errorno == 0) {
                        // console.log(response.data.result)

                        const outputArray = response.data.result.map(item => {
                            // console.log(item)

                            setformData({
                                id: item.id, 
                                companyname: item.companyname, 
                                companyaddress: item.companyaddress, 
                                contactperson: item.contactperson,
                                email: item.email, 
                                phone: item.phone, 
                                loginEmail: item.username
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();
        }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };

    return (
        <Form formData={formData} ref={formDataRef} disabled={false}>
            <GroupItem cssClass="form-group" colCount={2}>
                <ButtonItem>
                    <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={pressEdit}
                    >
                    <span className="dx-button-text">
                        Edit
                    </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem>
                    <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={pressChangePassword}
                    >
                    <span className="dx-button-text">
                        Change Password
                    </span>
                    </ButtonOptions>
                </ButtonItem>
            </GroupItem>

            <Item
                dataField={'id'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Organization ID' />
            </Item>

            <Item
                dataField={'companyname'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Organization Name' />
            </Item>

            <Item
                dataField={'companyaddress'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Address' />
            </Item>

            <Item
                dataField={'contactperson'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Contact Person' />
            </Item>

            <Item
                dataField={'phone'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Phone Number' />
            </Item>

            <Item
                dataField={'email'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Contact Email' />
            </Item>

            <Item
                dataField={'loginEmail'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Login Email' />
            </Item>
        </Form>
    )
}
import { createRoot } from 'react-dom/client';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import SciChartExample from './scichart';
import DataSource from 'devextreme/data/data_source';

import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'

import {
    NumericAxis,
    SciChartSurface,
    XyDataSeries,
    EllipsePointMarker,
    XyScatterRenderableSeries,
    EAxisAlignment,
    ETitlePosition,
    ETextAlignment,
    SciChartJsNavyTheme,
    ZoomPanModifier,
    VerticalLineAnnotation,
    HorizontalLineAnnotation,
    EAutoRange,
    visibleRange
} from "scichart";
import { DpiHelper } from 'scichart/Charting/Visuals/TextureManager/DpiHelper';
import ReactDOM from 'react-dom';

export default function DoctorPatientECGDatagrid() {

    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [patientToken, setPatientToken] = useState("");
    const [linkedCustomerName, setLinkedCustomerName] = useState('');
    const [linkedCustomerID, setLinkedCustomerID] = useState('');
    const [linkedDoctorName, setLinkedDoctorName] = useState('');
    const [patientName, setPatientName] = useState('');
    const [messages, setMessages] = useState([]);
    const [waveform, setWaveform] = useState([]);
    const [waveformIsOn, setWaveformIsOn] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const containerRef = useRef(null);
    const sciChartSurfaceRef = useRef(null);
    SciChartSurface.useWasmFromCDN();

    function formatDuration(durationInMs) {
        const totalSeconds = Math.floor(durationInMs / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        let formattedDuration = '';
        if (hours > 0) {
            formattedDuration += `${hours}h `;
        }
        if (minutes > 0) {
            formattedDuration += `${minutes}m `;
        }
        if (seconds > 0 || formattedDuration === '') {
            formattedDuration += `${seconds}s`;
        }

        return formattedDuration.trim();
    }

    function formatLocalDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        const second = String(date.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`.toUpperCase();
      }

    const fetchMessage = async (loadOptions) => {
        try {
            const page = Math.floor(loadOptions.skip / loadOptions.take) + 1;
            const size = loadOptions.take;

            console.log("Load Options:", loadOptions);
            console.log("Page:", page, "Size:", size);

            const response = await axios.post(backendlink + 'doctor/getpatientecgrecords',
                {
                    patienttoken: location.state.patientToken,
                    page: page,
                    size: size
                },
                {
                    headers: {
                        token: user.token
                    }
                });

            if (response.data.errorno == 0) {
                const outputArray = response.data.result.map(item => ({
                    uid: item.id,
                    // sharedDate: new Date(item.sharedDate * 1000).toISOString().replace('T', ' ').replace('Z', '').slice(0, 19),
                    // recordDate: new Date(item.recordedDate * 1000).toISOString().replace('T', ' ').replace('Z', '').slice(0, 19),
                    sharedDate: item.sharedDate === null ? null : formatLocalDate(new Date(item.sharedDate * 1000)),
                    recordDate: item.recordedDate === null ? null : formatLocalDate(new Date(item.recordedDate * 1000)),
                    recordType: item.recordType.toUpperCase(),
                    recordDuration: formatDuration(item.recordDuration),
                    aiResult: item.aiAbnormalResult + ' Abnormal Found',
                    bpm: item.bpm + ' BPM',
                }));

                console.log("Fetched Data:", outputArray);
                console.log("Total Count:", response.data.meta.total);

                return {
                    data: outputArray,
                    totalCount: response.data.meta.total,
                };
            } else {
                throw new Error(response.data.errmessage);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };

    const [dataSource] = useState(new DataSource({
        load: fetchMessage,
    }));

    const fetchData = async () => {
        try {
            const response = await axios.post(backendlink + 'doctor/getpatient', {
                doctortoken: user.userToken,
                patienttoken: location.state.patientToken
            }, {
                headers: {
                    token: user.token
                }
            });

            if (response.data.errorno == 0) {
                const outputArray = response.data.result.map(item => {
                    setLinkedCustomerName(item.linkedcustomer);
                    setLinkedCustomerID(item.linkedcustomerid);
                    setLinkedDoctorName(item.linkeddoctor);
                    setPatientName(item.name);
                    fetchMessage({ skip: 0, take: pageSize });
                });
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            alert(error);
        }
    };

    function getWaveformData(uid) {
        const handleClick = (e) => {
            e.preventDefault();

            const fetchScatterplotData = async () => {
                try {
                    const response = await axios.post(backendlink + 'doctor/getpatientecgscatterplot', {
                        ecgrecordid: uid,
                        patienttoken: location.state.patientToken
                    }, {
                        headers: {
                            token: user.token
                        }
                    });

                    if (response.data.errorno == 0) {
                        const { result } = response.data;
                        const { xValues, yValues, metadata, xValues2, yValues2, metadata2 } = result.reduce((acc, item) => {
                            if (item.hr >= 15) {
                                acc.xValues.push(item.hr);
                                acc.yValues.push(item.afProb);
                                acc.metadata.push({ customData: item.id });
                            } else {
                                acc.xValues2.push(item.hr);
                                acc.yValues2.push(item.afProb);
                                acc.metadata2.push({ customData: item.id });
                            }
                            return acc;
                        }, { xValues: [], yValues: [], metadata: [], xValues2: [], yValues2: [], metadata2: [] });

                        const minXValue = Math.min(...xValues);
                        const maxXValue = Math.max(...xValues);
                        const visibleRangeMin = minXValue - 10;
                        const visibleRangeMax = maxXValue + 10;

                        const initSciChart = async () => {
                            const { wasmContext, sciChartSurface } = await SciChartSurface.create("waveform-" + uid, {
                                theme: new SciChartJsNavyTheme(),
                                loader: false
                            });

                            sciChartSurface.background = "#F9F9FF";
                            sciChartSurfaceRef.current = sciChartSurface;

                            // sciChartSurface.style = { width: "400px", height: "400px" };

                            sciChartSurface.title = "AF Possibility and Heart Rate";
                            sciChartSurface.titleStyle = {
                                position: ETitlePosition.Top,
                                alignment: ETextAlignment.Center,
                                fontSize: 20,
                                color: "#181C23"
                            };

                            sciChartSurface.xAxes.add(new NumericAxis(wasmContext, {
                                axisTitle: "Heart Rate (BPM)",
                                visibleRange: { min: visibleRangeMin, max: visibleRangeMax },

                                axisTitleStyle: {
                                    fontSize: 12,
                                    color: "#181C23"
                                },
                                labelStyle: {
                                    color: "#181C23" // Change the color of the axis numbers
                                },
                                autoTicks: false, // Set autoTicks to false to manually control deltas
                                majorDelta: 10, // Example major delta
                                minorDelta: 10 // Set minorDelta equal to majorDelta to effectively disable minor gridlines
                            }));

                            sciChartSurface.yAxes.add(new NumericAxis(wasmContext, {
                                autoRange: EAutoRange.Never,
                                visibleRange: { min: -0.2, max: 1.1 },
                                axisTitle: "AF Probability",
                                axisAlignment: EAxisAlignment.Left,
                                axisTitleStyle: {
                                    fontSize: 12,
                                    color: "#181C23"
                                },
                                labelStyle: {
                                    color: "#181C23" // Change the color of the axis numbers
                                },
                                autoTicks: false, // Set autoTicks to false to manually control deltas
                                majorDelta: 0.2,
                                minorDelta: 0.2
                            }));

                            const scatterSeries = new XyScatterRenderableSeries(wasmContext, {
                                pointMarker: new EllipsePointMarker(wasmContext, {
                                    width: 10,
                                    height: 10,
                                    fill: '#34A5BB'
                                }),
                                dataSeries: new XyDataSeries(wasmContext, {
                                    xValues,
                                    yValues,
                                    metadata
                                }),
                            });

                            const scatterSeries2 = new XyScatterRenderableSeries(wasmContext, {
                                pointMarker: new EllipsePointMarker(wasmContext, {
                                    width: 10,
                                    height: 10,
                                    fill: '#34A5BB'
                                }),
                                dataSeries: new XyDataSeries(wasmContext, {
                                    xValues: xValues2,
                                    yValues: yValues2,
                                    metadata: metadata2
                                }),
                            });

                            sciChartSurface.renderableSeries.add(scatterSeries);
                            sciChartSurface.renderableSeries.add(scatterSeries2);

                            // Add some interactivity modifiers
                            sciChartSurface.chartModifiers.add(new ZoomPanModifier({ enableZoom: false }));

                            // Add dotted line annotations
                            sciChartSurface.annotations.add(new VerticalLineAnnotation({
                                x1: 10,
                                stroke: "#005CBB",
                                strokeDashArray: [2, 2], // Dotted line
                                strokeThickness: 2
                            }));

                            sciChartSurface.annotations.add(new HorizontalLineAnnotation({
                                y1: 70,
                                stroke: "#005CBB",
                                strokeDashArray: [2, 2], // Dotted line
                                strokeThickness: 2
                            }));

                            sciChartSurface.domCanvas2D.addEventListener('click', (mouseEvent) => {
                                const premultipliedX = mouseEvent.offsetX * DpiHelper.PIXEL_RATIO;
                                const premultipliedY = mouseEvent.offsetY * DpiHelper.PIXEL_RATIO;
                                const hitTestInfo = scatterSeries.hitTestProvider.hitTest(premultipliedX, premultipliedY, 10);
                                const hitTestInfo2 = scatterSeries2.hitTestProvider.hitTest(premultipliedX, premultipliedY, 10);
                                if (hitTestInfo.isHit) {
                                    handlePointClick(hitTestInfo.metadata.customData, uid);
                                }
                                if (hitTestInfo2.isHit) {
                                    handlePointClick(hitTestInfo2.metadata.customData, uid);
                                }
                            });

                            document.getElementById("waveform-" + uid).style.width = "500px";
                            document.getElementById("waveform-" + uid).style.height = "500px";
                        };



                        initSciChart();
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    alert(error);
                }
            };
            fetchScatterplotData();
        };

        return (
            <button onClick={handleClick} style={{ backgroundColor: '#005CBB', color: 'white', padding: '10px 20px', borderRadius: '5px', border: "none", marginBottom: "10px", cursor: 'pointer' }}>
                View Details
            </button>
        );
    }

    const handlePointClick = async (dataPoint, uid) => {
        try {
            const response = await axios.post(backendlink + 'doctor/getpatientecglinedata', {
                patienttoken: location.state.patientToken,
                ecgdataid: dataPoint
            }, {
                headers: {
                    token: user.token
                }
            });

            const ecgData = response.data.result[0].arrECGdata;
            const datapointinfo = response.data.result[0];
            const container = document.getElementById('ecg-' + uid);
            document.getElementById('ecg-' + uid).style.height = '500px';
            if (container) {
                const root = createRoot(container);
                root.render(<SciChartExample ecgData={ecgData} uid={dataPoint} datapointinfo={datapointinfo} />);
            }
        } catch (error) {
            console.error('Error fetching ECG data:', error);
        }   
    };

    const waveformCell = (cellData) => (
        <div>
            {getWaveformData(cellData.data.uid)}
            <br />
        </div>
    );

    const DataRow = (rowInfo) => (
        <React.Fragment>
            <tr role="row">
                <td role="gridcell">{rowInfo.data.sharedDate}</td>
                <td role="gridcell">{rowInfo.data.recordDate}</td>
                <td role="gridcell">{rowInfo.data.recordType}</td>
                <td role="gridcell">{rowInfo.data.recordDuration}</td>
                <td role="gridcell">{rowInfo.data.aiResult}</td>
                <td role="gridcell">{rowInfo.data.bpm}</td>
                <td role="gridcell">{waveformCell(rowInfo)}</td>
            </tr>
            <tr className="waveform-row">
                {/* <td colSpan={3}>
                    <div id={"waveform-" + rowInfo.data.uid} style={{ width: "0px", height: "0px" }} />
                </td>
                <td colSpan={3}>
                    <div id={"ecg-" + rowInfo.data.uid} />
                </td> */}
                <td colSpan={9}>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div id={"waveform-" + rowInfo.data.uid} style={{ width: "0px", height: "0px" }} />
                        <div id={"ecg-" + rowInfo.data.uid} style={{ flex: '1 1 500px', height: "0px", margin: "10px" }} />
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );

    useEffect(() => {
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);
            fetchData();
        }
    }, [user]);

    return (
        <React.Fragment>
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Organization:</th>
                        <td>{linkedCustomerName}</td>
                        <th>Organization ID:</th>
                        <td>{linkedCustomerID}</td>
                        <th>Doctor:</th>
                        <td>{linkedDoctorName}</td>
                        <th>Patient:</th>
                        <td>{patientName}</td>
                    </tr>
                </thead>
            </table>

            <DataGrid
                dataSource={dataSource}
                dataRowRender={DataRow}
                remoteOperations={true}
            >
                <Column dataField="sharedDate" caption='Shared Date' width="15%" />
                <Column dataField="recordDate" caption='Record Date' width="15%" />
                <Column dataField="recordType" caption='ECG Mode' width="15%" />
                <Column dataField="recordDuration" caption='Record Duration' width="15%" allowSorting={false} />
                <Column dataField='aiResult' caption="AI result" width="20%" allowSorting={false} />
                <Column dataField='bpm' caption="BPM" width="10%" allowSorting={false} />
                <Column dataField="waveform" caption='' minWidth="120" width="10%" allowSorting={false} />
                <Pager allowedPageSizes={[5, 10]} showPageSizeSelector={true} />
                <Paging
                    defaultPageSize={pageSize}
                    defaultPageIndex={pageIndex}
                />
                <Lookup />
            </DataGrid>
        </React.Fragment>
    );
}
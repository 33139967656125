import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate, useSearchParams } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { PartnerBillingDatagrid } from '../../components';

  export default function PartnerCustomer() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (user.usertype == "CP" || user.usertype == "DIST") {

        } else {
            navigate('/');
        }
    }, []);

    return (
        <React.Fragment>
            <h2 className={'content-block'}>{'Billing Overview'}</h2>

            <div className={'content-block dx-card responsive-paddings'}>
                <PartnerBillingDatagrid />
            </div>
        </React.Fragment>
      );
  }
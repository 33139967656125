import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import './CustomerDoctorInfoList.css'; 

export default function PartnerCustomerInfoList() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [doctorToken, setDoctorToken] = useState("")
    const [doctor, setDoctor] = useState([])

    const location = useLocation();

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ id: '', doctorname: '', companyname: '', companyaddress: '', contactperson: '', email: '', phone: '', loginEmail: '', description: '', linkedcustomer: ''  });


    const formDataRef = useRef(null)

    function pressEdit() {
        navigate('/customer-doctor-edit', { state: { doctorToken: doctorToken } });
    }

    function pressChangePassword() {
        navigate('/customer-doctor-changepassword', { state: { doctorToken: doctorToken } });
    }

    useEffect(() => {
        // console.log(location.state)
        
        if (location.state?.doctorToken == null) {
            navigate('/');
        } else {
            setDoctorToken(location.state.doctorToken);
            // console.log(location.state.doctorToken)
            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'customer/getdoctor',
                    {
                        usertoken: user.userToken,
                        doctortoken: location.state.doctorToken
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });

                    if (response.data.errorno == 0) {
                        console.log(response.data.result)

                        const outputArray = response.data.result.map(item => {
                            // console.log(item)

                            setDoctor({
                                id: item.id, 
                                doctorname: item.name, 
                                companyaddress: item.companyaddress, 
                                contactperson: item.contactperson,
                                email: item.email, 
                                phone: item.phone, 
                                loginEmail: item.username,
                                description: item.description0,
                                username: item.username,
                                linkedcustomer: item.linkedcustomer
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();
        }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };

    return (
        <div className="card-container">
            <div className="card">
                <h2>Doctor Information</h2>
                <div className="user-info">
                    <div className="user-info-item">
                        <span className="label">Account ID (Auto Generated):</span>
                        <span className="value">{doctor.id}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Name:</span>
                        <span className="value">{doctor.doctorname}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Description:</span>
                        <span className="value">{doctor.description}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Address:</span>
                        <span className="value">{doctor.companyaddress}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Contact Person:</span>
                        <span className="value">{doctor.contactperson}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Phone Number:</span>
                        <span className="value">{doctor.phone}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Contact Email:</span>
                        <span className="value">{doctor.email}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Login Email:</span>
                        <span className="value">{doctor.loginEmail}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Linked Organization:</span>
                        <span className="value">{doctor.linkedcustomer}</span>
                    </div>
                    {/* Add more fields as needed */}
                </div>
            </div>
        </div>
    )
}
// export const navigation = [
//     {
//         text: 'Home',
//         path: '/home',
//         icon: 'home'
//     },
//     {
//         text: 'Examples',
//         icon: 'folder',
//         items: [
//             {
//                 text: 'Profile',
//                 path: '/profile'
//             },
//             {
//                 text: 'Tasks',
//                 path: '/tasks'
//             }
//         ]
//     }
// ];

import PersonAddIcon from "./svg/Property 1=person_add_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import RequestQuoteIcon from "./svg/Property 1=request_quote_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import PatientListIcon from "./svg/Property 1=patient_list_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import BillingIcon from "./svg/VitogramPortal_MainMenuIcon_Billing.svg";
import BillingLogIcon from "./svg/VitogramPortal_MainMenuIcon_BillingLog.svg";
import ActivityLogIcon from "./svg/VitogramPortal_MainMenuIcon_ActivityLog.svg";
import DoctorListIcon from "./svg/doctorList.svg";

export const navigationPartner = [
    // {
    //     text: 'Home',
    //     path: '/home',
    //     icon: 'home'
    // },
    {
        text: 'User List',
        path: '/partner-customer',
        icon: PatientListIcon
    },
    {
        text: 'Billing',
        path: '/partner-billing',
        icon: BillingIcon
    },
    {
        text: 'Add New User',
        path: '/partner-adduser',
        icon: PersonAddIcon
    },
    {
        text: 'Activity Log',
        path: '/partner-activity-log',
        icon: ActivityLogIcon
    },
    {
        text: 'Billing Log',
        path: '/partner-billing-log',
        icon: BillingLogIcon
    }
];

export const navigationCustomer = [
    // {
    //     text: 'Home',
    //     path: '/home',
    //     icon: 'home'
    // },
    // {
    //     text: 'User List',
    //     path: '/customer-doctor1',
    //     icon: PatientListIcon
    // },
    {
        text: 'Doctor List',
        path: '/customer-doctor',
        icon: DoctorListIcon
    },
    {
        text: 'Patient List',
        path: '/customer-patient',
        icon: PatientListIcon
    },
    // {
    //     text: 'Add New User',
    //     path: '/customer-addusers',
    //     icon: PersonAddIcon
    // },
    {
        text: 'Activity Log',
        path: '/customer-activity-log',
        icon: ActivityLogIcon
    }
];

export const navigationDoctor = [
    // {
    //     text: 'Home',
    //     path: '/home',
    //     icon: 'home'
    // },
    {
        text: 'Patient List',
        path: '/doctor-patient',
        icon: PatientListIcon
    },
    // {
    //     text: 'Add New User',
    //     path: '/doctor-addusers',
    //     icon: PersonAddIcon
    // },
    {
        text: 'Activity Log',
        path: '/doctor-activity-log',
        icon: ActivityLogIcon
    }
];

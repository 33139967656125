import React from 'react';
import "./GroupCaption.scss";

const GroupCaption = ({ iconName, caption }) => (
  <React.Fragment>
    <i className={`dx-icon dx-icon-${iconName}`}></i>
    <span>{caption}</span>
  </React.Fragment>
);
export default GroupCaption;

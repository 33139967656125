import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { useNavigate, useLocation } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { CustomerDoctorListDatagrid, CustomerPatientChangepasswordForm, CustomerPatientEditForm, CustomerPatientInfoList, CustomerPatientLogDatagrid } from '../../components';
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";
  import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
  import Form, { ButtonItem, ButtonOptions } from "devextreme-react/form";
  import { Toast } from "devextreme-react/toast";

  export default function CustomerPatientInfoPage() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [title, setTitle] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const [isPatientVerified, setPatientVerified] = useState(false);

    const breadcrumbItemsBase = [
        { label: 'Home', path: '/', active: false },
        { label: 'Patient List', path: '/customer-patient', active: false }
        
    ];

    const tabTitle = [
        "Patient Information", 
        "Edit Patient Profile",
        "Change Password",
        "Patient Activity Log",
    ];

    const [tabSelectedIndex, setTabSelectedIndex] = useState(1)

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });    

    const CustomActionComponent = () => (
      <div>
        {!isPatientVerified ? (
          <Form>
            <ButtonItem
              marginTop={"auto"}
              marginBottom={"0px"}
              style={"margin-top: auto; margin-bottom: 0px"}
              width={"100%"}
            >
              <ButtonOptions
                width={"100%"}
                type={"default"}
                useSubmitBehavior={false}
                onClick={sendActivationEmail}
              >
                <span className="dx-button-text">Send Activation Email</span>
              </ButtonOptions>
            </ButtonItem>
          </Form>
        ) : (
          ""
        )}
      </div>
    );

    const sendActivationEmail = async () => {
      try {
        const response = await axios
          .post(
            backendlink + "customer/sendactivationemail",
            {
              userToken: location.state?.patientToken,
            },
            {
              headers: {
                token: user.token,
              },
            }
          )
          .then((res) => {
            if (res.data.errorno === 0) {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "success",
                message: "Activation email sent",
              });
            } else {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "error",
                message: res.data.errmessage,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    };

    function onHiding() {
      setToastConfig({
        ...toastConfig,
        isVisible: false,
      });
    }

    function onTitleClick(e) {
        console.log("click" + e.itemIndex);
        var itemIndex = e.itemIndex;
        setTabSelectedIndex(itemIndex)
        setTitle(tabTitle[itemIndex]);
        setBreadcrumbItems(breadcrumbItemsBase.concat( { label: tabTitle[itemIndex], path: '#', active: true }));
        setFetchTrigger((prevTrigger) => prevTrigger + 1);
    }

    useEffect(() => {
      setPatientVerified(location?.state?.isPatientVerified);

        if (user.usertype == "Customer") {
            setTitle(tabTitle[0]);
            setBreadcrumbItems(breadcrumbItemsBase.concat( { label: tabTitle[0], path: '#', active: true }));
        } else {
            navigate('/');
        }
    }, []);

    return (
      <React.Fragment>
        <Breadcrumb
          title={title}
          items={breadcrumbItems}
          actionItem={<CustomActionComponent />}
        />
        <TabPanel
          className={"content-block dx-card responsive-paddings"}
          swipeEnabled={false}
          scrollByContent={true}
          showNavButtons={true}
          onTitleClick={onTitleClick}
        >
          <Item title="Patient Information">
            <CustomerPatientInfoList fetchTrigger={fetchTrigger} />
          </Item>

          <Item title="Edit Patient Information">
            <CustomerPatientEditForm />
          </Item>

          <Item title="Change Password">
            <CustomerPatientChangepasswordForm />
          </Item>

          <Item title="Patient Activity Log">
            <CustomerPatientLogDatagrid />
          </Item>
        </TabPanel>
        <Toast
          visible={toastConfig.isVisible}
          message={toastConfig.message}
          type={toastConfig.type}
          onHiding={onHiding}
          displayTime={2000}
        />
      </React.Fragment>
    );
}
import { Routes, Route, Navigate } from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ForgetPasswordForm, ValidateOtpForm, ResetPasswordForm } from './components';
import './index.css';
import VitomeIcon from "./svg/Vitome_RGB_Colour_RegisteredTrademark.svg";

export default function UnauthenticatedContent() {
    return (
        <div className="unauthenticated-content">
        <Routes>
            <Route
                path='/login' 
                element={

                <SingleCard>
                    <LoginForm />
                </SingleCard>

                }
            />
            <Route
                path='/forgetpassword' 
                element={
                <SingleCard 
                    title="Forget Password"
                    // description="Reset your password via email"
                >
                    <ForgetPasswordForm />
                </SingleCard>
                }
            />
            <Route
                path='/validateotp' 
                element={
                <SingleCard 
                    title="Forget Password"
                    // description="Enter the verification code received in the email"
                >
                    <ValidateOtpForm />
                </SingleCard>
                }
            />
            <Route
                path='/resetpassword' 
                element={
                <SingleCard 
                    title="Reset Password"
                >
                    <ResetPasswordForm />
                </SingleCard>
                }
            />
            {/* <Route
                path='/create-account'
                element={
                <SingleCard title="Sign Up">
                    <CreateAccountForm />
                </SingleCard>
                }
            />
            <Route 
                path='/reset-password'
                element={
                <SingleCard
                    title="Reset Password"
                    description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
                >
                    <ResetPasswordForm />
                </SingleCard>
                }
            /> */}
            {/* <Route
                path='/change-password/:recoveryCode'
                element={
                <SingleCard title="Change Password">
                    <ChangePasswordForm />
                </SingleCard>
                }
            /> */}
            <Route path='*' element={<Navigate to={'/login'} />}></Route>
        </Routes>
        </div>
    );
}

import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate, useSearchParams } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { CustomerDoctorListDatagrid, CustomerPatientListDatagrid } from '../../components';
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";
  import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
  import Form, {
    ButtonItem,
    ButtonOptions,
} from 'devextreme-react/form';

  export default function CustomerPatientPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
    // const [tabDefaultSelectedIndex, setTabDefaultSelectedIndex] = useState(0);

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;

        setTabSelectedIndex(itemIndex)
    }

    useEffect(() => {
        if (user.usertype == "Customer") {
            var searchParamTabSelectedIndex = searchParams.get('tabSelectedIndex')

            if (searchParamTabSelectedIndex != null) {
                // setTabDefaultSelectedIndex(searchParamTabSelectedIndex)
                setTabSelectedIndex(searchParamTabSelectedIndex)
            }
        } else {
            navigate('/');
        }
    }, []);


    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Patient List', path: '/customer-patient', active: true }
      ];

    const CustomActionComponent = () => (
        <div>
            <Form>
                <ButtonItem
                    marginTop={'auto'}
                    marginBottom={'0px'}
                    style={"margin-top: auto; margin-bottom: 0px"}
                    width={'100%'}
                    >
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={false}
                        onClick={() => navigate('/customer-add-patient')}
                    >
                        <span className="dx-button-text">
                            Create New Patient
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>    
        </div>
    );

    return (
        <React.Fragment>
            <Breadcrumb title="Patient List" items={breadcrumbItems} actionItem={<CustomActionComponent />} />
            <div className={'content-block dx-card responsive-paddings'}>
                <CustomerPatientListDatagrid />
            </div>
        </React.Fragment>
      );
  }
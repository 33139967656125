import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './PartnerCustomerListDatagrid.css';

export default function PartnerCustomerListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [formData, setformData] = useState({ startDate: '', endDate: '' });
    const formDataRef = useRef(null)

    const [loadingExport, setLoadingExport] = useState(false);

    function handleRowClick(e) {
        const customerToken = e.data.customerToken;
        navigate('/partner-customer-info', { state: { customerToken: customerToken } });
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'partner/getpartnercustomers',
                {
                    usertoken: user.userToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    // console.log(response.data.result)
                    const outputArray = response.data.result[0].map(item => {
                        var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                        return { customerName: item.companyname, customerID: item.id, status: statusString, linkedPartner: item.partnerName, address: item.companyaddress, contactPerson: item.contactperson, phone: item.phone, email: item.email, customerToken: item.userToken };
                    });
                    setUsers(outputArray)
                }
                else if (response.data.errorno == 330) {
                    setUsers([])
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

        var dateStartdate = new Date(Date.now() - 86400000)
        var dateEnddate = new Date()

        setformData({ startDate: dateStartdate, endDate: dateEnddate })
    }, [user]);

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    return (
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClick}>
                <Column dataField='customerID' caption='Organization ID' alignment='left' />
                <Column dataField='customerName' caption='Organization Name' />
                <Column dataField='address' caption='Company Address' />
                <Column dataField='contactPerson' caption='Contact Person' />
                <Column dataField='phone' caption='Phone Number' />
                <Column dataField='email' caption='Contact Email' />

                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>

            {/* <form onSubmit={onSubmitExport}>
                <Form formData={formData} ref={formDataRef} disabled={false} colCount={3}>

                    <Item
                        dataField={'startDate'}
                        editorType={'dxDateBox'}
                        editorOptions={dateoptions}
                    >
                        <Label text='Start Date' />
                    </Item>

                    <Item
                        dataField={'endDate'}
                        editorType={'dxDateBox'}
                        editorOptions={dateoptions}
                    >
                        <Label text='End Date' />
                    </Item>

                    <ButtonItem
                        marginTop={'auto'}
                        marginBottom={'0px'}
                        style={"margin-top: auto; margin-bottom: 0px"}
                        width={'100%'}
                    >
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            useSubmitBehavior={true}
                        >
                            <span className="dx-button-text">
                            {
                                loadingExport
                                ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                : ('Export')
                            }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                </Form>
            </form> */}
        </React.Fragment>
    )

}

import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';

// import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import WaveSurfer from 'wavesurfer.js'
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js'


// import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

// import WaveSurfer from 'https://unpkg.com/wavesurfer.js@7/dist/wavesurfer.esm.js'

export default function DoctorPatientMessageDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [patientToken, setPatientToken] = useState("")

    const location = useLocation();

    const [linkedCustomerName, setLinkedCustomerName] = useState('');
    const [linkedCustomerID, setLinkedCustomerID] = useState('');
    const [linkedDoctorName, setLinkedDoctorName] = useState('');
    const [patientName, setPatientName] = useState('');

    const [messages, setMessages] = useState([]);

    const [waveform, setWaveform] = useState([]);
    const [waveformIsOn, setWaveformIsOn] = useState([]);

    const containerRef = useRef(null)

    const fetchData = async () => {
        try {
            const response = await axios.post(backendlink + 'doctor/getpatient',
                {
                    doctortoken: user.userToken,
                    patienttoken: location.state.patientToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });

            if (response.data.errorno == 0) {
                // console.log(response.data.result)

                const outputArray = response.data.result.map(item => {
                    // console.log(item)

                    setLinkedCustomerName(item.linkedcustomer)
                    setLinkedCustomerID(item.linkedcustomerid)
                    setLinkedDoctorName(item.linkeddoctor)
                    setPatientName(item.name)

                    fetchMessage()
                });
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };

    const fetchMessage = async () => {
        // console.log(user.token)
        // console.log(user.userToken)
        // console.log(location.state.patientToken)

        try {
            const response = await axios.post(backendlink + 'doctor/getpatientlogs',
                {
                    usertoken: user.userToken,
                    patienttoken: location.state.patientToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });

            if (response.data.errorno == 0) {
                // console.log(response.data)

                const outputArray = response.data.result[0].map(item => {
                    var transactionID = ""
                    var labelIDstring = ""
                    var sharedDateString = ""
                    var recordDateString = ""
                    var detailsString = ""

                    item.forEach(element => {
                        var isRecordConsistent = false

                        if (transactionID == "") {
                            transactionID = element.transactionID
                            isRecordConsistent = true
                        } else {
                            if (transactionID == element.transactionID) {
                                isRecordConsistent = true
                            }
                        }

                        if (isRecordConsistent == true) {
                            switch (element.logActivity) {
                                case 'Auscultation Success':

                                    var adjustedDate = new Date(element.logTimestamp)

                                    var userTimezoneOffset = adjustedDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
                                    var datelogTimestamp = new Date(adjustedDate.getTime() + userTimezoneOffset);


                                    if (recordDateString == "") {
                                        recordDateString = datelogTimestamp.getFullYear() + '-' + (((datelogTimestamp.getMonth() + 1) < 10) ? ('0' + (datelogTimestamp.getMonth() + 1)) : (datelogTimestamp.getMonth() + 1)) + '-' + (((datelogTimestamp.getDate()) < 10) ? ('0' + (datelogTimestamp.getDate())) : (datelogTimestamp.getDate())) + " " + (((datelogTimestamp.getHours()) < 10) ? ('0' + (datelogTimestamp.getHours())) : (datelogTimestamp.getHours())) + ':' + (((datelogTimestamp.getMinutes()) < 10) ? ('0' + (datelogTimestamp.getMinutes())) : (datelogTimestamp.getMinutes())) + ':' + (((datelogTimestamp.getSeconds()) < 10) ? ('0' + (datelogTimestamp.getSeconds())) : (datelogTimestamp.getSeconds()));
                                    }

                                    if (detailsString != "") {
                                        detailsString += "<br/>"
                                    }

                                    // Replace "Abnormal" with red color and "normal" with "No murmur found"
                                    detailsString += element.logDescription
                                        .replace(/Abnormal/g, '<span style="color: #BA1A1A;">Risk of heart murmur</span>')
                                        .replace(/Normal/g, 'No murmur found');

                                    break;

                                case 'Share to Doctor':
                                    var adjustedDate = new Date(element.logTimestamp)

                                    var userTimezoneOffset = adjustedDate.getTimezoneOffset() * 60000; // Convert offset to milliseconds
                                    var datelogTimestamp = new Date(adjustedDate.getTime() + userTimezoneOffset);

                                    if (sharedDateString == "") {
                                        sharedDateString = datelogTimestamp.getFullYear() + '-' + (((datelogTimestamp.getMonth() + 1) < 10) ? ('0' + (datelogTimestamp.getMonth() + 1)) : (datelogTimestamp.getMonth() + 1)) + '-' + (((datelogTimestamp.getDate()) < 10) ? ('0' + (datelogTimestamp.getDate())) : (datelogTimestamp.getDate())) + " " + (((datelogTimestamp.getHours()) < 10) ? ('0' + (datelogTimestamp.getHours())) : (datelogTimestamp.getHours())) + ':' + (((datelogTimestamp.getMinutes()) < 10) ? ('0' + (datelogTimestamp.getMinutes())) : (datelogTimestamp.getMinutes())) + ':' + (((datelogTimestamp.getSeconds()) < 10) ? ('0' + (datelogTimestamp.getSeconds())) : (datelogTimestamp.getSeconds()));
                                    }

                                    if (element.logDescription.includes(',')) {
                                        let arrSpiltDescription = element.logDescription.split(",");

                                        if (arrSpiltDescription.length >= 2) {
                                            labelIDstring = arrSpiltDescription[1];
                                            switch (labelIDstring) {
                                                case 'P':
                                                    labelIDstring = 'Pulmonic';
                                                    break;
                                                case 'A':
                                                    labelIDstring = 'Aortic';
                                                    break;
                                                case 'T':
                                                    labelIDstring = 'Tricuspid';
                                                    break;
                                                case 'M':
                                                    labelIDstring = 'Mitral';
                                                    break;
                                                default:
                                                    labelIDstring = '';
                                                    break;
                                            }
                                            if (labelIDstring != '') {
                                                detailsString += "<br/>" + "Measuring Position: " + labelIDstring;
                                            }
                                        }
                                    }

                                    break;

                                default:
                                    break;
                            }
                        }
                    });

                    return { uid: transactionID, labelid: labelIDstring, sharedDate: sharedDateString, recordDate: recordDateString, details: detailsString };
                });

                outputArray.sort((a, b) => new Date(b.sharedDate) - new Date(a.sharedDate));

                setMessages(outputArray)
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    }

    function gridOnOptionChanged(e) {
        if (e.name == 'paging') {
            setWaveform([])
            setWaveformIsOn([])
        }
    }

    function getWaveformData(uid) {
        const handleClick = (e) => {
            e.preventDefault();

            const fetchWaveformData = async () => {
                // console.log(waveformIsOn[rowIndex])
                if (waveformIsOn[uid] === undefined || waveformIsOn[uid] === null || waveformIsOn[uid] == false) {
                    setWaveformIsOn({ ...waveformIsOn, [uid]: true });
                    try {
                        const response = await axios.post(backendlink + 'doctor/getmedicalrecord',
                            {
                                auscultationid: uid,
                                patienttoken: location.state.patientToken
                            },
                            {
                                headers: {
                                    token: user.token
                                }
                            });
                        if (response.data.errorno == 0) {
                            console.log(response.data.result);
                            const temp = "data:audio/wav;base64," + response.data.result;
                            setWaveform({ ...waveform, [uid]: temp });

                            const audioElement = document.createElement('audio');
                            audioElement.controls = true;
                            audioElement.controlsList = 'noplaybackrate nodownload';
                            audioElement.style.width = "100%";
                            audioElement.src = temp;

                            // Append the WaveSurfer container first
                            const container = document.getElementById('waveform-' + uid);
                            const waveformContainer = document.createElement('div');
                            waveformContainer.id = 'waveform-container-' + uid;
                            container.appendChild(waveformContainer);

                            // Append the audio element to the container
                            container.appendChild(audioElement);

                            const ws = WaveSurfer.create({
                                container: '#waveform-container-' + uid,
                                height: 100,
                                cursorWidth: 1,
                                waveColor: 'red',
                                progressColor: 'blue',
                                normalize: true,
                                media: audioElement, // Pass the custom audio element
                                plugins: [TimelinePlugin.create({
                                    container: '#waveform-container-' + uid, // Ensure the timeline plugin uses the correct container
                                    timeInterval: 0.1,
                                    primaryLabelInterval: 5,
                                    secondaryLabelInterval: 1,
                                })],
                            });

                            // Create a div for the timeline plugin
                            const timelineContainer = document.createElement('div');
                            waveformContainer.appendChild(timelineContainer);

                            ws.on('click', () => {
                                ws.play()
                            })
                        } else {
                            alert(response.data.errmessage)
                        }
                    } catch (error) {
                        alert(error);
                    }
                }
            };
            fetchWaveformData();
        }

        return (
            <button onClick={handleClick} style={{ backgroundColor: '#005CBB', color: 'white', padding: '10px 20px', borderRadius: '5px', border: "none", marginBottom: "10px", cursor: 'pointer' }}>
                Heartsound 
            </button>
        );
    }

    const waveformCell = (cellData) => (
        <div>
            {getWaveformData(cellData.data.uid)}
            <br />
            {/* {waveform[cellData.data.uid] 
            && <audio controls style={{ width: "100%" }} controlsList="noplaybackrate nodownload">
                <source src={waveform[cellData.data.uid]} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            } */}
        </div>
    );

    const DataRow = (rowInfo) => (
        <React.Fragment>
            <tr role="row" >
                <td role="gridcell">{rowInfo.data.sharedDate}</td>
                <td role="gridcell">{rowInfo.data.recordDate}</td>
                <td role="gridcell">{React.createElement("div", { dangerouslySetInnerHTML: { __html: rowInfo.data.details } })}</td>
                {/* <td role="gridcell">{rowInfo.data.labelid}</td> */}
                <td role="gridcell">{waveformCell(rowInfo)}</td>
            </tr>
            <tr className="waveform-row">
                <td colSpan={6}>
                    <div id={"waveform-" + rowInfo.data.uid} />
                </td>
            </tr>
        </React.Fragment>
    );

    useEffect(() => {
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);
            fetchData();
        }
    }, [user]);

    return (
        <React.Fragment>
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Organization:</th>
                        <td>{linkedCustomerName}</td>
                        <th>Organization ID:</th>
                        <td>{linkedCustomerID}</td>
                        <th>Doctor:</th>
                        <td>{linkedDoctorName}</td>
                        <th>Patient:</th>
                        <td>{patientName}</td>
                    </tr>
                </thead>
            </table>

            <DataGrid dataSource={messages} dataRowRender={DataRow} onOptionChanged={gridOnOptionChanged} >
                <Column dataField="sharedDate" caption='Shared Date' width="20%" />
                <Column dataField="recordDate" caption='Record Date' width="20%" />
                <Column dataField="details" caption='Details' width="30%" allowSorting={false} />
                {/* <Column dataField='logTime' caption="Patient's Note" width="10%" allowSorting={false} /> */}
                <Column dataField="waveform" caption='Waveform' minWidth="320" width="30%" allowSorting={false} />
                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                {/* <FilterRow visible={true} /> */}
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>
        </React.Fragment>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import { DoctorPatientAddForm } from '../../components';
import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

export default function CustomerActivityLogPage() {
    const { user } = useAuth();

    const [formData, setformData] = useState({ startDate: '', endDate: '' });
    const formDataRef = useRef(null)

    const [loadingExport, setLoadingExport] = useState(false);

    useEffect(() => {
        var dateStartdate = new Date(Date.now() - 86400000)
        var dateEnddate = new Date()

        setformData({ startDate: dateStartdate, endDate: dateEnddate })
    }, [user]);

    const onSubmitExport = async (e) => {
        e.preventDefault();
        setLoadingExport(true);

        var currentDate = new Date();
        var currentDateString = currentDate.getFullYear() + (((currentDate.getMonth()+1) < 10) ? ('0' + (currentDate.getMonth()+1)) : (currentDate.getMonth()+1)) + (((currentDate.getDate()) < 10) ? ('0' + (currentDate.getDate())) : (currentDate.getDate()))
        var sanitzedCompanyName = user.companyname.replace(/[^a-zA-Z0-9]/g, '_');
        var fileName = 'integratedportal_' + sanitzedCompanyName + '_Log_' + currentDateString;

        var startDateString = formData.startDate.getFullYear() + '-' + (((formData.startDate.getMonth()+1) < 10) ? ('0' + (formData.startDate.getMonth()+1)) : (formData.startDate.getMonth()+1)) + '-' + (((formData.startDate.getDate()) < 10) ? ('0' + (formData.startDate.getDate())) : (formData.startDate.getDate())) + 'T00:00:00.000Z'
        var endDateString = formData.endDate.getFullYear() + '-' + (((formData.endDate.getMonth()+1) < 10) ? ('0' + (formData.endDate.getMonth()+1)) : (formData.endDate.getMonth()+1)) + '-' + (((formData.endDate.getDate()) < 10) ? ('0' + (formData.endDate.getDate())) : (formData.endDate.getDate())) + 'T23:59:59.000Z'

        try {
            const response = await axios.post(backendlink + 'customer/exportpatientlogs',

            {
                startdate: startDateString,
                enddate: endDateString,
                usertoken: user.userToken
            }, 
            {
                headers: {
                    token: user.token
                },
                responseType: 'blob' // set the response type to blob 
            }).then((res)=>{
                console.log(res)

                setLoadingExport(false);

                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName + '.xlsx');
                document.body.appendChild(link);
                link.click();
            });
        } catch (error) {
            setLoadingExport(false);

            console.log(error);
            alert(error);
        }
    }

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Activity Log', path: '/doctor-patient-info', active: true }
          ];
    

    return (
        <React.Fragment>
            <Breadcrumb title="Activity Log" items={breadcrumbItems} />
            <div className={'content-block dx-card responsive-paddings'}>

                <form onSubmit={onSubmitExport}>
                    <Form formData={formData} ref={formDataRef} disabled={false} colCount={3}>

                        <Item
                            dataField={'startDate'}
                            editorType={'dxDateBox'}
                            editorOptions={dateoptions}
                        >
                            <Label text='Start Date' />
                        </Item>

                        <Item
                            dataField={'endDate'}
                            editorType={'dxDateBox'}
                            editorOptions={dateoptions}
                        >
                            <Label text='End Date' />
                        </Item>

                        <ButtonItem
                            marginTop={'auto'}
                            marginBottom={'0px'}
                            style={"margin-top: auto; margin-bottom: 0px"}
                            width={'100%'}
                        >
                            <ButtonOptions
                                width={'100%'}
                                type={'default'}
                                useSubmitBehavior={true}
                            >
                                <span className="dx-button-text">
                                    {
                                        loadingExport
                                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                            : ('Export')
                                    }
                                </span>
                            </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </form>
            </div>
        </React.Fragment>
    );
}
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    GroupItem,
    SimpleItem
} from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';
import { isValidPhoneNumber } from 'react-phone-number-input';
import GroupCaption from '../../pages/group-caption/GroupCaption';

export default function CustomerPatientEditForm() {
    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [patientToken, setPatientToken] = useState("")

    const location = useLocation();

    const [loading, setLoading] = useState(false);
   
    const [additionalAttributes, setAdditionalAttributes] = useState([]);

    const [formData, setformData] = useState({
      name: "",
      linkedCustomerToken: "",
      linkedCustomerName: "",
      linkedDoctorToken: "",
      linkedDoctorName: "",
      address: "",
      contactPerson: "",
      email: "",
      phone: "",
      countryCode: "",
      phoneForOtp: "",
      loginEmail: "",
      status: "",
      enableAusculto: false,
      enableEcg: false,
      enableBloodPressure: false,
      preferredLanguage: "",
      ...((additionalAttributes || []).reduce((acc, field) => {
        if (field.editorType === "dxSwitch") {
          return { ...acc, [field.dataField]: false };
        }
        return { ...acc, [field.dataField]: '' };
      }, {})),
    });

    const formDataRef = useRef(null)

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const [linkedDoctorItems, setLinkedDoctorItems] = useState([]);
    const [preferredLanguageItems, setPreferredLanguageItems] = useState([]);  

    const countryCodes = [
      { code: "852", name: "Hong Kong (+852)" },
      { code: "853", name: "Macau (+853)" },
      { code: "86", name: "China (+86)" },
  ];

    const [countryCodeItems, setCountryCodeItems] = useState([]);

    const preferredLanguages = [
      { code: "tc", name: "Traditional Chinese" },
      { code: "en", name: "English" },
      { code: "sc", name: "Simplified Chinese" },
  ];
  
    const fetchDoctor = async () => {
        try {
            const response = await axios.post(backendlink + 'customer/getalldoctors',
            {
                customertoken: user.userToken
            },
            {
                headers: {
                    token: user.token
                }
            });
            if (response.data.errorno == 0) {
                // console.log(response.data.result)
                const outputArray = response.data.result[0].map(item => {
                    return { id: item.userToken, text: item.name};
                });
                setLinkedDoctorItems
                (outputArray)
            }
            else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };

    const groupCaptionNamedRender = (iconName) => {
        const groupCaptionRender = (data) => (
          <GroupCaption
            iconName={iconName}
            {...data}
          />
        );
        return groupCaptionRender;
    };
 
    const getPatientAttribute = async () => {
      try {
        const response = await axios.get(
          backendlink + "customer/getpatientattribute",
          {
            params: {
              organizationToken: user.userToken,
              userToken: location.state.patientToken,
            },
            headers: {
              token: user.token,
            },
          }
        );

        if (
          response?.data?.errorno === 0 &&
          response?.data?.result?.length > 0
        ) {
          const notesEditorOptions = { height: 90, maxLength: 200 };

          const mappedAttributes =
            response?.data?.result?.map((field) => {
              const baseField = {
                dataField: field.code,
                label: {
                  text: field.name,
                },
                value: field.value,
                isMandatory: field.isMandatory,
              };

              switch (field.dataType) {
                case "string":
                  return { ...baseField, editorType: "dxTextBox" };
                case "text":
                  return {
                    ...baseField,
                    editorType: "dxTextArea",
                    editorOptions: { notesEditorOptions },
                  };
                case "number":
                  return { ...baseField, editorType: "dxNumberBox" };
                case "dropdown":
                  return {
                    ...baseField,
                    editorType: "dxSelectBox",
                    editorOptions: {
                      items: field.options,
                    },
                  };
                case "boolean":
                  return { ...baseField, editorType: "dxSwitch" };
                case "date":
                  return {
                    ...baseField,
                    editorType: "dxDateBox",
                    editorOptions: {
                      type: "date",
                    },
                  };
                default:
                  return { ...baseField, editorType: "dxTextBox" };
              }
            }) || [];
          setAdditionalAttributes(mappedAttributes);
          return mappedAttributes;
        }
      } catch (error) {
        console.error(error);
      }
    };       

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        
        let mandatoryFieldsMissing = [];
        let invalidFields = [];

        additionalAttributes.forEach((field) => {
          if (field.isMandatory) {
            if (field.editorType !== "dxSwitch" && !formData[field.dataField]) {
              mandatoryFieldsMissing.push(field.label.text);
            }
          }

          if (
            field.editorType === "dxNumberBox" &&
            (formData[field.dataField] && formData[field.dataField] <= 0)
          ) {
            invalidFields.push(`${field.label.text} must be greater than 0.`);
          }

          if (
            field.dataField === "dateOfBirth" &&
            formData[field.dataField] > new Date()
          ) {
            invalidFields.push("Date of birth cannot set future date");
          }
        });

        if (!validateEmail(formData.email)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Email format error",
            });
        } else if (!validateEmail(formData.loginEmail)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Login Email format error",
            });
        } else if ((!formData.countryCode && formData.phoneForOtp) || (formData.countryCode && !formData.phoneForOtp)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Country Code and Phone Number for OTP should be pair",
            });
        } else if (formData.countryCode && formData.phoneForOtp && !validatePhoneForOtp(formData.countryCode, formData.phoneForOtp)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Invalid Phone Number for OTP error",
            });
        } else if (mandatoryFieldsMissing.length > 0) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'error',
            message: `Please complete all required fields: ${mandatoryFieldsMissing.join(", ")}`
          });
        } else if (invalidFields.length > 0) {
          setToastConfig({
            ...toastConfig,
            isVisible: true,
            type: 'error',
            message: invalidFields.join("\n")
          });
        } else {
            setLoading(true);

            try {
                var countryCodeString = formData.countryCode? formData.countryCode.replace(/[^0-9+]/g, '') : ""
                var phoneForOtpString = formData.phoneForOtp? formData.phoneForOtp.replace(/[^0-9+]/g, '') : ""

                const dynamicFields = additionalAttributes.reduce((acc, field) => {
                  const defaultValue = field.editorType === 'dxSwitch' ? false : '';
                  let value = formData[field.dataField] !== undefined ? formData[field.dataField] : defaultValue;

                  if (field.editorType === 'dxDateBox') {
                    if (value instanceof Date) {
                      value = value.toISOString().split('T')[0];
                    }
                  }
                
                  return { ...acc, [field.dataField]: value };
                }, {});

                const response = await axios.post(backendlink + 'customer/editpatient',
                {
                    usertoken: user.userToken,
                    patienttoken: patientToken,
                    name: formData.name,
                    parentToken: formData.linkedDoctorToken,
                    description0: formData.address,
                    description1: formData.contactPerson,
                    email: formData.email,
                    phone: formData.phone,
                    countryCode: String(countryCodeString),
                    phoneForOtp: String(phoneForOtpString),
                    username: formData.loginEmail,
                    isEnabled: formData.status,
                    preferredLanguage: formData.preferredLanguage,
                    enableAusculto: formData.enableAusculto,
                    enableEcg: formData.enableEcg,
                    enableBloodPressure: formData.enableBloodPressure,
                    ...dynamicFields
                }, 
                {
                    headers: {
                        token: user.token
                    }        
                }).then((res)=>{
                    setLoading(false);

                    if (res.data.errorno == 0){
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'success',
                            // message: 'New partner has been added',
                            message: 'Organization updated',
                        });    

                        // formData.current.instance.clear();
                    } else{
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'error',
                            message: res.data.errmessage,
                        });    
                    }
                });
            } catch (error) {
                setLoading(false);

                // console.log(error);
                alert(error);
            }
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const validatePhoneForOtp = (countryCode, phoneForOtp) => {
      return isValidPhoneNumber(`+${countryCode}${phoneForOtp}`);
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(
          backendlink + "customer/getpatient",
          {
            usertoken: user.userToken,
            patienttoken: location.state.patientToken,
          },
          {
            headers: {
              token: user.token,
            },
          }
        );

        return response.data;
      } catch (error) {
        alert(error);
      }
    };

    useEffect(() => {
      if (location.state?.patientToken == null) {
        navigate("/");
      } else {
        const fetchPatientData = async () => {
          try {
            setPatientToken(location.state.patientToken);
            setCountryCodeItems(countryCodes);
            setPreferredLanguageItems(preferredLanguages);

            const patientData = await fetchData();

            const additionalAttributes = await getPatientAttribute();

            if (patientData.errorno === 0) {
              const outputArray = patientData.result.map((item) => {
                var countryCodeString = item.countryCode
                  ? item.countryCode.replace(/[^0-9+]/g, "")
                  : "";
                var phoneForOtpString = item.phoneForOtp
                  ? item.phoneForOtp.replace(/[^0-9+]/g, "")
                  : "";
                setformData({
                  name: item.name,
                  linkedCustomerToken: item.linkedcustomertoken,
                  linkedCustomerName: item.linkedcustomer,
                  linkedDoctorToken: item.parentUserToken,
                  linkedDoctorName: item.linkeddoctor,
                  address: item.description0,
                  contactPerson: item.description1,
                  email: item.email,
                  phone: item.phone,
                  countryCode: String(countryCodeString),
                  phoneForOtp: String(phoneForOtpString),
                  loginEmail: item.username,
                  status: item.isEnabled,
                  preferredLanguage: item.preferredLanguage? item.preferredLanguage : 'tc',
                  enableAusculto: item.enableAusculto? item.enableAusculto : false,
                  enableEcg: item.enableEcg? item.enableEcg : false,
                  enableBloodPressure: item.enableBloodPressure? item.enableBloodPressure : false,
                  ...((additionalAttributes || []).reduce((acc, field) => {
                    return { ...acc, [field.dataField]: field.value };
                  }, {})),
                });

                fetchDoctor();
              });
            } else {
              alert(patientData.errmessage);
            }
          } catch (error) {
            // console.log(error);
            alert(error);
          }
        };

        fetchPatientData();
      }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };

    var statusItems = [
        { id: true, text: 'Active'},
        { id: false, text: 'Inactive'},
    ];

    return (
      <form onSubmit={onSubmit}>
        <Form
          formData={formData}
          ref={formDataRef}
          disabled={false}
          colCount={4}
          style={{ paddingTop: "20px" }}
        >
          <GroupItem
            iconName="user"
            captionRender={groupCaptionNamedRender("user")}
            caption="Personal Data"
            colSpan={4}
          >
            <GroupItem colCount={2}>
              <Item dataField={"name"} editorType={"dxTextBox"}>
                <Label text="Patient Name" />
              </Item>

              <Item
                dataField={"linkedDoctorToken"}
                editorType={"dxSelectBox"}
                editorOptions={{
                  items: linkedDoctorItems,
                  displayExpr: "text",
                  valueExpr: "id",
                }}
              >
                <Label text="Linked Doctor" />
              </Item>

              <Item dataField={"address"} editorType={"dxTextBox"}>
                <Label text="Address" />
              </Item>

              <Item
                dataField={"status"}
                editorType={"dxSelectBox"}
                editorOptions={{
                  items: statusItems,
                  displayExpr: "text",
                  valueExpr: "id",
                }}
              >
                <Label text="Status" />
              </Item>
              <Item
                dataField={"preferredLanguage"}
                editorType={"dxSelectBox"}
                editorOptions={{
                  items: preferredLanguageItems,
                  displayExpr: "name",
                  valueExpr: "code",
                }}
              >
                <Label text="Preferred Language" />
              </Item>              
            </GroupItem>
          </GroupItem>

          <GroupItem
            captionRender={groupCaptionNamedRender("card")}
            caption="Login Data"
            colSpan={2}
          >
            <GroupItem colCount={1}>
              <Item>
                <Label text="Login Phone Number" />
                <div style={{ display: "flex" }}>
                  <SelectBox
                    dataSource={countryCodeItems}
                    value={formData.countryCode}
                    displayExpr="name"
                    valueExpr="code"
                    placeholder="Select Country Code"
                    onValueChanged={(e) => {
                      setformData({ ...formData, countryCode: e.value });
                    }}
                    style={{ marginRight: "10px" }}
                  />
                  <TextBox
                    placeholder="Mobile Number"
                    value={formData.phoneForOtp}
                    onValueChanged={(e) => {
                      setformData({ ...formData, phoneForOtp: e.value });
                    }}
                    style={{ flexGrow: 1 }}
                  />
                </div>
              </Item>

              <Item dataField={"loginEmail"} editorType={"dxTextBox"}>
                <Label text="Login Email" />
              </Item>
            </GroupItem>
          </GroupItem>

          <GroupItem
            captionRender={groupCaptionNamedRender("home")}
            caption="Emergency Contact Information"
            colSpan={2}
          >
            <Item dataField={"contactPerson"} editorType={"dxTextBox"}>
              <Label text="Contact Person" />
            </Item>

            <GroupItem colCount={2}>
              <Item colSpan={1} dataField={"phone"} editorType={"dxTextBox"}>
                <Label text="Contact Number" />
              </Item>

              <Item colSpan={1} dataField={"email"} editorType={"dxTextBox"}>
                <Label text="Contact Email" />
              </Item>
            </GroupItem>
          </GroupItem>

          {additionalAttributes.length > 0 && (
            <GroupItem
              captionRender={groupCaptionNamedRender("home")}
              caption="Additional Attribute(s)"
              colSpan={4}
            >
              <GroupItem colCount={2}>
                {additionalAttributes.map((item, index) => (
                  <SimpleItem key={index} {...item} />
                ))}
              </GroupItem>
            </GroupItem>
          )}

          <GroupItem
            captionRender={groupCaptionNamedRender("home")}
            caption="Privilege"
            colSpan={4}
          >
            <GroupItem colCount={4}>
              <Item dataField={"enableAusculto"} editorType={"dxSwitch"}>
                <Label text="Ausculto" />
              </Item>
              <Item dataField={"enableEcg"} editorType={"dxSwitch"}>
                <Label text="ECG" />
              </Item>
              <Item dataField={"enableBloodPressure"} editorType={"dxSwitch"}>
                <Label text="Blood Pressure" />
              </Item>
            </GroupItem>
          </GroupItem>
          <ButtonItem colSpan={4} cssClass="full-width-button">
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Save"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>

        <Toast
          visible={toastConfig.isVisible}
          message={toastConfig.message}
          type={toastConfig.type}
          onHiding={onHiding}
          displayTime={2000}
        />
      </form>
    );
}
import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { Popup } from 'devextreme-react';
import axios from 'axios';
import { backendlink } from './config.js';
import { useAuth } from './contexts/auth';
import "./index.css";


export default function Content() {
    const [title, setTitle] = useState(appInfo.title);
    const [isAccepting, setIsAccepting] = useState(false);
    // const user = JSON.parse(Cookies.get("vitogram_integrated_user"));
    const { user, setUser, loading } = useAuth();
    const [showDisclaimer, setShowDisclaimer] = useState(true);

    useEffect(() => {
        const cookieUser = Cookies.get("vitogram_integrated_user");
        if (cookieUser) {
            const parsedUser = JSON.parse(cookieUser);
            const userType = parsedUser.usertype;
            const userCompanyName = parsedUser.companyname;
            const newTitle = userType === "DIST"  ? appInfo.disttitle : 
                             userType === "Customer" || userType === "CP" ? userCompanyName :
                             userType === "Doctor" ? appInfo.doctortitle : 
                             appInfo.title;
            setTitle(newTitle);
        }
    }, []);

    const acceptDisclaimer = async () => {
        setIsAccepting(true);
        try {
            const result = await axios.post(backendlink + 'user/acceptdisclaimer', {
                userToken: user.userToken
            }, {
                headers: {
                    token: user.token
                }
            });
            if (result.data.errorno === 0) {
                const updatedUser = { ...user, disclaimer: true };
                setUser(updatedUser);
                Cookies.set("vitogram_integrated_user", JSON.stringify(updatedUser), { expires: 7 });
            } else {
                alert(result.data.errmessage);
            }
        } catch (error) {
            alert(error);
        } finally {
            setIsAccepting(false);
        }
    };

    return (
        <>
        <SideNavBarLayout title={title}>
            <Routes>
                {routes.map(({ path, element }) => (
                    <Route
                        key={path}
                        path={path}
                        element={element}
                    />
                ))}
                <Route
                    path='*'
                    element={<Navigate to='/home' />}
                />
            </Routes>
            <Footer>
                v{appInfo.versionString} - Copyright © {new Date().getFullYear()} {appInfo.title}
                <br />
                All trademarks or registered trademarks are property of their
                respective owners.
                <br />
                <a href='/pp_eng_v1_7.pdf' target='_blank'>Privacy Policy</a>
                <a href='/tc_eng_v1_3.pdf' target='_blank' style={{marginLeft: "5px"}}>T&C</a>
            </Footer>
        </SideNavBarLayout>
        {user.disclaimer == false && <Popup
                visible={true}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={false}
                showTitle={false}
                // width="1200px"
                // height="658px"
                maxHeight={"658px"}
                maxWidth={"1200px"}
                position={{ my: 'center', at: 'center', of: window }}
            >
                <p className="disclaimertitle">
                    IMPORTANT NOTICE <br></br> DATA MANAGEMENT DISCLAIMER
                </p>
                <p className='disclaimercontent' >
                    Please read this disclaimer carefully before using the Vitome Integrated Portal. By clicking "I Acknowledge" below, you confirm your understanding and acceptance of the following terms:
                    <br></br>
                    <br></br>
                    This portal provides data management capabilities with permanent consequences. When data is deleted or modified within your account, Vitome cannot restore it to its previous state. Vitome disclaims all liability for any data loss, corruption, or modification resulting from your actions within the portal. You assume full responsibility for managing and maintaining your data backups. We strongly recommend exercising caution before performing any data deletion or modification.
                </p>
                <button
             
                    className="disclaimerbutton"
                    onClick={acceptDisclaimer}
                >
                    {isAccepting ? 'Processing...' : 'I Acknowledge and Accept these Terms'}
                </button>
            </Popup>}
        </>
    );
}


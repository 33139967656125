import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import './CustomerPatientInfoList.css'; 

export default function CustomerPatientInfoList ({ fetchTrigger }) {
    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [patient, setPatient] = useState([]);
    const [patientToken, setPatientToken] = useState("")

    const location = useLocation();

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ name: '', linkedCustomerToken: '', linkedCustomerName: '', linkedDoctorToken: '', linkedDoctorName: '', address: '', contactPerson: '', email: '', phone: '', countryCode: '', phoneForOtp: '', loginEmail: '', status: '' });
    const formDataRef = useRef(null)

    const [additionalAttributes, setAdditionalAttributes] = useState([]);

    const getPatientAttribute = async () => {
      try {
        const response = await axios.get(
          backendlink + "customer/getpatientattribute",
          {
            params: {
              organizationToken: user.userToken,
              userToken: location.state.patientToken,
            },
            headers: {
              token: user.token,
            },
          }
        );

        if (
          response?.data?.errorno === 0 &&
          response?.data?.result?.length > 0
        ) {
          const mappedAttributes = response?.data?.result?.map((attribute) => {
            return {
              label: attribute.name,
              value: attribute.value,
              dataField: attribute.code,
            };
          });

          setAdditionalAttributes(mappedAttributes);
        } else {
          console.log(response?.data?.errmessage);
        }
      } catch (error) {
        console.error(error);
      }
    };

    function pressEdit() {
        navigate('/customer-patient-edit', { state: { patientToken: patientToken } });
    }

    function pressChangePassword() {
        navigate('/customer-patient-changepassword', { state: { patientToken: patientToken } });
    }

    function pressLog() {
        navigate('/customer-patient-log', { state: { patientToken: patientToken } });
    }

    useEffect(() => {
        // console.log(location.state)
        
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);            
            getPatientAttribute();
            // console.log(user.token)
            // console.log(user.userToken)
            // console.log(location.state.patientToken)
            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'customer/getpatient',
                    {
                        usertoken: user.userToken,
                        patienttoken: location.state.patientToken
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });

                    if (response.data.errorno == 0) {
                        // console.log(response.data.result)

                        const outputArray = response.data.result.map(item => {
                            // console.log(item)

                            var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')
                            var countryCodeString = item.countryCode? item.countryCode.replace(/[^0-9+]/g, '') : ""
                            var phoneForOtpString = item.phoneForOtp? item.phoneForOtp.replace(/[^0-9+]/g, '') : ""

                            setPatient({
                                name: item.name, 
                                linkedCustomerToken: item.linkedcustomertoken, 
                                linkedCustomerName: item.linkedcustomer, 
                                linkedDoctorToken: item.parentUserToken, 
                                linkedDoctorName: item.linkeddoctor, 
                                address: item.description0,
                                contactPerson: item.description1, 
                                email: item.email, 
                                phone: item.phone, 
                                countryCode: String(countryCodeString),
                                phoneForOtp: String(phoneForOtpString),
                                loginEmail: item.username,
                                status: statusString,
                                ...additionalAttributes.reduce((acc, field) => {
                                    return { ...acc, [field.dataField]: field.value };
                                  }, {}),
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();
        }
    }, [user, fetchTrigger]);

    const readonlyptions = { readOnly: 'true' };

    return (
      <div className="card-container">
        <div className="card">
          <h2>Patient Information</h2>
          <div className="user-info">
            <div className="user-info-item">
              <span className="label">Name:</span>
              <span className="value">{patient.name}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Linked Organization:</span>
              <span className="value">{patient.linkedCustomerName}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Linked Doctor:</span>
              <span className="value">{patient.linkedDoctorName}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Address:</span>
              <span className="value">{patient.address}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Contact Person:</span>
              <span className="value">{patient.contactPerson}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Contact Email:</span>
              <span className="value">{patient.email}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Login Email:</span>
              <span className="value">{patient.loginEmail}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Status:</span>
              <span className="value">{patient.status}</span>
            </div>
            <div className="user-info-item">
              <span className="label">Login Phone Number:</span>
              <span className="value">{patient.phoneForOtp ? `+${patient.countryCode}-${patient.phoneForOtp}` : 'NA'}</span>
            </div>
            {additionalAttributes.map((attribute) => (
              <div key={attribute.dataField} className="user-info-item">
                <span className="label">{attribute.label}:</span>
                <span dangerouslySetInnerHTML={{ __html: attribute.value.replace(/\n/g, "<br />") }} />
              </div>
            ))}
            {/* Add more fields as needed */}
          </div>
        </div>
      </div>
    );
}
import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.css';


const Breadcrumb = ({ title, items, actionItem }) => {
  return (
    <div className="breadcrumb-container">
      <div className="breadcrumb-header">
          <div className="breadcrumb-title-row">
            <h2 className='breadcrumb-title'>{title}</h2>
            <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {items.map((item, index) => (
                <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`}>
                  {item.active ? (
                    item.label
                  ) : (
                    <Link to={item.path}>{item.label}</Link>
                  )}
                </li>
              ))}
            </ol>
          </nav>  
          </div>
        {actionItem && <div className="breadcrumb-action">{actionItem}</div>}
      </div>
      
    </div>
  );
};

export default Breadcrumb;
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';
import { isValidPhoneNumber } from 'react-phone-number-input';
import GroupCaption from '../../pages/group-caption/GroupCaption';

export default function DoctorPatientEditForm() {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [users, setUsers] = useState([]);

  const [patientToken, setPatientToken] = useState("");

  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [formData, setformData] = useState({
    name: "",
    linkedCustomerToken: "",
    linkedCustomerName: "",
    address: "",
    contactPerson: "",
    email: "",
    phone: "",
    countryCode: "",
    phoneForOtp: "",
    loginEmail: "",
    status: "",
  });
  const formDataRef = useRef(null);

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "success",
    message: "",
  });

  const countryCodes = [
    { code: "852", name: "Hong Kong (+852)" },
    { code: "853", name: "Macau (+853)" },
    { code: "86", name: "China (+86)" },
  ];

  const [countryCodeItems, setCountryCodeItems] = useState([]);

  const groupCaptionNamedRender = (iconName) => {
    const groupCaptionRender = (data) => (
      <GroupCaption iconName={iconName} {...data} />
    );
    return groupCaptionRender;
  };

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log("dsadasda", formData)
    // console.log('rrrr', user.token)
    // console.log('ddd', user.userToken)
    // console.log('aaaaa', patientToken)

    if (!validateEmail(formData.email)) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: "error",
        message: "Email format error",
      });
    } else if (!validateEmail(formData.loginEmail)) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: "error",
        message: "Login Email format error",
      });
    } else if (
      (!formData.countryCode && formData.phoneForOtp) ||
      (formData.countryCode && !formData.phoneForOtp)
    ) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: "error",
        message: "Country Code and Phone Number for OTP should be pair",
      });
    } else if (
      formData.countryCode &&
      formData.phoneForOtp &&
      !validatePhoneForOtp(formData.countryCode, formData.phoneForOtp)
    ) {
      setToastConfig({
        ...toastConfig,
        isVisible: true,
        type: "error",
        message: "Invalid Phone Number for OTP error",
      });
    } else {
      setLoading(true);

      try {
        var countryCodeString = formData.countryCode
          ? formData.countryCode.replace(/[^0-9+]/g, "")
          : "";
        var phoneForOtpString = formData.phoneForOtp
          ? formData.phoneForOtp.replace(/[^0-9+]/g, "")
          : "";

        const response = await axios
          .post(
            backendlink + "doctor/editpatient",
            {
              usertoken: user.userToken,
              patienttoken: patientToken,
              name: formData.name,
              description0: formData.address,
              description1: formData.contactPerson,
              email: formData.email,
              phone: formData.phone,
              countryCode: String(countryCodeString),
              phoneForOtp: String(phoneForOtpString),
              username: formData.loginEmail,
              isEnabled: formData.status,
            },
            {
              headers: {
                token: user.token,
              },
            }
          )
          .then((res) => {
            setLoading(false);

            if (res.data.errorno == 0) {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "success",
                // message: 'New partner has been added',
                message: "Patient updated",
              });

              // formData.current.instance.clear();
            } else {
              setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: "error",
                message: res.data.errmessage,
              });
            }
          });
      } catch (error) {
        setLoading(false);

        // console.log(error);
        alert(error);
      }
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePhoneForOtp = (countryCode, phoneForOtp) => {
    return isValidPhoneNumber(`+${countryCode}${phoneForOtp}`);
  };

  useEffect(() => {
    // console.log(location.state)

    if (location.state?.patientToken == null) {
      navigate("/");
    } else {
      setPatientToken(location.state.patientToken);
      setCountryCodeItems(countryCodes);

      const fetchData = async () => {
        try {
          const response = await axios.post(
            backendlink + "doctor/getpatient",
            {
              doctortoken: user.userToken,
              patienttoken: location.state.patientToken,
            },
            {
              headers: {
                token: user.token,
              },
            }
          );

          if (response.data.errorno == 0) {
            // console.log(response.data.result)

            const outputArray = response.data.result.map((item) => {
              console.log(item);
              var countryCodeString = item.countryCode
                ? item.countryCode.replace(/[^0-9+]/g, "")
                : "";
              var phoneForOtpString = item.phoneForOtp
                ? item.phoneForOtp.replace(/[^0-9+]/g, "")
                : "";

              setformData({
                name: item.name,
                linkedCustomerToken: item.linkedcustomertoken,
                linkedCustomerName: item.linkedcustomer,
                address: item.description0,
                contactPerson: item.description1,
                email: item.email,
                phone: item.phone,
                countryCode: String(countryCodeString),
                phoneForOtp: String(phoneForOtpString),
                loginEmail: item.username,
                status: item.isEnabled,
              });
            });
          } else {
            alert(response.data.errmessage);
          }
        } catch (error) {
          // console.log(error);
          alert(error);
        }
      };

      fetchData();
    }
  }, [user]);

  const readonlyptions = { readOnly: "true" };

  var statusItems = [
    { id: true, text: "Active" },
    { id: false, text: "Inactive" },
  ];

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData} ref={formDataRef} disabled={false} colCount={4}>
        <GroupItem
          iconName="user"
          captionRender={groupCaptionNamedRender("user")}
          caption="Personal Data"
          colSpan={4}
        >
          <GroupItem colCount={2}>
            <Item dataField={"name"} editorType={"dxTextBox"}>
              <Label text="Patient Name" />
            </Item>

            <Item dataField={"address"} editorType={"dxTextBox"}>
              <Label text="Address" />
            </Item>

            <Item
              dataField={"status"}
              editorType={"dxSelectBox"}
              editorOptions={{
                items: statusItems,
                displayExpr: "text",
                valueExpr: "id",
              }}
            >
              <Label text="Status" />
            </Item>
          </GroupItem>
        </GroupItem>

        <GroupItem
          captionRender={groupCaptionNamedRender("card")}
          caption="Login Data"
          colSpan={2}
        >
          <GroupItem colCount={1}>
            <Item>
              <Label text="Login Phone Number" />
              <div style={{ display: "flex" }}>
                <SelectBox
                  dataSource={countryCodeItems}
                  value={formData.countryCode}
                  displayExpr="name"
                  valueExpr="code"
                  placeholder="Select Country Code"
                  onValueChanged={(e) => {
                    setformData({ ...formData, countryCode: e.value });
                  }}
                  style={{ marginRight: "10px" }}
                />
                <TextBox
                  placeholder="Mobile Number"
                  value={formData.phoneForOtp}
                  onValueChanged={(e) => {
                    setformData({ ...formData, phoneForOtp: e.value });
                  }}
                  style={{ flexGrow: 1 }}
                />
              </div>
            </Item>

            <Item dataField={"loginEmail"} editorType={"dxTextBox"}>
              <Label text="Login Email" />
            </Item>
          </GroupItem>
        </GroupItem>

        <GroupItem
          captionRender={groupCaptionNamedRender("home")}
          caption="Emergency Contact Information"
          colSpan={2}
        >
          <GroupItem colCount={1}>
            <Item dataField={"contactPerson"} editorType={"dxTextBox"}>
              <Label text="Contact Person" />
            </Item>

            <Item dataField={"phone"} editorType={"dxTextBox"}>
              <Label text="Contact Number" />
            </Item>

            <Item dataField={"email"} editorType={"dxTextBox"}>
              <Label text="Contact Email" />
            </Item>
          </GroupItem>
        </GroupItem>

        <ButtonItem colSpan={4} cssClass="full-width-button">
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Save"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>

      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={2000}
      />
    </form>
  );
}
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { CustomerDoctorAddForm, CustomerPatientAddForm } from '../../components';
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";
  import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
  export default function CustomerAddDoctorPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

    useEffect(() => {
        if (user.usertype == "Customer") {

        } else {
            navigate('/');
        }
    }, []);

    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Doctor List', path: '/doctor-patient-info', active: false },
        { label: 'Create New Doctor', path: '#', active: true }
    ];
    
    return (
        <React.Fragment>
            <Breadcrumb title="Create New Doctor" items={breadcrumbItems} />
            <div className={'content-block dx-card responsive-paddings'}>
                <CustomerDoctorAddForm />
            </div>
        </React.Fragment>
      );
  }
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { PartnerCustomerAddForm, PartnerDoctorAddForm, PartnerPatientAddForm } from '../../components';
  import HomeHealthIcon from "../../svg/Property 1=home_health_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";

  export default function PartnerCustomerEditPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)

    useEffect(() => {
        if (user.usertype == "CP" || user.usertype == "DIST") {

        } else {
            navigate('/');
        }
    }, []);

    return (
        <React.Fragment>
            <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true}>
                <Item title="New Organization" icon={HomeHealthIcon}> 
                    <PartnerCustomerAddForm />
                </Item>

                <Item title="New Doctor" icon={StethoscopeIcon}> 
                    <PartnerDoctorAddForm />
                </Item>
    
                <Item title="New Patient" icon={PersonIcon}> 
                    <PartnerPatientAddForm />
                </Item>
            </TabPanel>
        </React.Fragment>
      );
  }
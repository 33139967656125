import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate, useSearchParams } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { DoctorPatientListDatagrid } from '../../components';
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import Breadcrumb from '../../components/breadcrumb/Breadcrumb';


  export default function CustomerDoctor() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [tabSelectedIndex, setTabSelectedIndex] = useState(0);
    const [tabDefaultSelectedIndex, setTabDefaultSelectedIndex] = useState(0);

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;

        setTabSelectedIndex(itemIndex)
    }

    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Patient List', path: '/doctor-patient-info', active: true }
      ];

    useEffect(() => {
        if (user.usertype == "Doctor") {
            var searchParamTabSelectedIndex = searchParams.get('tabSelectedIndex')

            if (searchParamTabSelectedIndex != null) {
                setTabDefaultSelectedIndex(searchParamTabSelectedIndex)
                setTabSelectedIndex(searchParamTabSelectedIndex)
            }
        } else {
            navigate('/');
        }
    }, []);

    return (
        <React.Fragment>
            <Breadcrumb title="Patient List" items={breadcrumbItems} />
            <div className={'content-block dx-card responsive-paddings'}>
                <DoctorPatientListDatagrid />
            </div>
        </React.Fragment>
      );
}
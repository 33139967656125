import React from 'react';
import ScrollView from 'devextreme-react/scroll-view';
import './single-card.scss';
import VitomeIcon from '../../svg/Vitome_RGB_Colour_RegisteredTrademark.svg'


export default function SingleCard({ title, description, children }) {
  return (
    <ScrollView height={'100%'} width={'100%'} className={'with-footer single-card'}>
      <div className={'cardwrapper'}>
      <div className={'dx-card content'}>
        <div className={'header'}>
          <div className="logo">
            <img src={VitomeIcon} alt="Vitome Icon" className="logo-img" />
          </div>
          <p className="integrated-portal-text">Integrated Portal</p>
          <div className={'cardcontent'}>
            <div className={'title'}>{title}</div>
            <div className={'description'}>{description}</div>
          {children}
          </div>
          </div>

        </div>
      </div>
    </ScrollView>
  )
}

import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

export default function CustomerPatientChangepasswordForm() {
    const { user } = useAuth();

    const navigate = useNavigate();

    const [patientToken, setPatientToken] = useState("")

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ id: '', password: '', confirmPassword: '' });
    const formDataRef = useRef(null)

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const fetchData = async () => {
        try {
            const response = await axios.post(backendlink + 'customer/getpatient',
            {
                usertoken: user.userToken,
                patienttoken: location.state.patientToken
            },
            {
                headers: {
                    token: user.token
                }
            });

            if (response.data.errorno == 0) {
                // console.log(response.data.result)

                const outputArray = response.data.result.map(item => {
                    // console.log(item)

                    setformData({
                        name: item.name, 
                        linkedCustomerToken: item.linkedcustomertoken, 
                        linkedCustomerName: item.linkedcustomer, 
                        linkedDoctorToken: item.parentUserToken, 
                        linkedDoctorName: item.linkeddoctor, 
                        address: item.description0,
                        contactPerson: item.description1, 
                        email: item.email, 
                        phone: item.phone, 
                        loginEmail: item.username,
                        status: item.isEnabled
                    });
                });
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log("dsadasda", formData)
        // console.log('aaaaa', partnerToken)

        setLoading(true);

        try {
            const response = await axios.post(backendlink + 'customer/changepassword',
            {
                usertoken: user.userToken,
                targettoken: patientToken,
                usertype: "Patient",
                password: formData.password,
                confirmPassword: formData.confirmPassword,
            }, 
            {
                headers: {
                    token: user.token
                }        
            }).then((res)=>{
                setLoading(false);

                if (res.data.errorno == 0){
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'success',
                        // message: 'New partner has been added',
                        message: 'Password changed',
                    });    

                    // setformData({ id: '', password: '', confirmPassword: '' })
                    fetchData();
                } else{
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'error',
                        message: res.data.errmessage,
                    });    
                }
            });
        } catch (error) {
            setLoading(false);

            // console.log(error);
            alert(error);
        }
    };

    useEffect(() => {
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);
    
            fetchData();
        }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };
    const passwordOptions = { mode: "password" };

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} ref={formDataRef} disabled={false}>
                {/* <Item
                    dataField={'id'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Organization ID' />
                </Item> */}

                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordOptions}
                >
                    <Label text='Password' />
                </Item>

                <Item
                    dataField={'confirmPassword'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordOptions}
                >
                    <Label text='Confirm Password' />
                </Item>

                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                        {
							loading
							? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
							: ('Change Password')
						}
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
        </form>
    )
}
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem,
    SimpleItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import './DoctorPatientInfoList.css'; 
import {
    SciChartSurface,
    NumericAxis,
    PieSegment,
    SciChartPieSurface,
    EPieType,
    XyDataSeries,
    FastLineRenderableSeries,
    EAutoRange,
    ENumericFormat,
    EAxisAlignment,
    LegendModifier,
    NumberRange,
    DateTimeNumericAxis,
    CategoryAxis,
    TextLabelProvider,
    RolloverModifier,
    ELabelProviderType,
    CursorModifier,
    SciChartJSLightTheme,
    SciChartJsNavyTheme
} from "scichart";


export default function DoctorPatientInfoList ({ fetchTrigger }) {
    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [patient, setPatient] = useState([]);
    const [patientToken, setPatientToken] = useState("")
    const [selectedDuration, setSelectedDuration] = useState(0);

    const handleDurationChange = (event) => {
        setSelectedDuration(event.target.value);
    };

    const location = useLocation();

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ name: '', linkedCustomerToken: '', linkedCustomerName: '', address: '', contactPerson: '', email: '', phone: '', countryCode: '', phoneForOtp: '', loginEmail: '', status: '' });
    const formDataRef = useRef(null)

    const [BPTable, setBPTable] = useState({
        mean_systolic_blood_pressure: 0,
        min_systolic_blood_pressure: 0,
        max_systolic_blood_pressure: 0,
        mean_diastolic_blood_pressure: 0,
        min_diastolic_blood_pressure: 0,
        max_diastolic_blood_pressure: 0,
        mean_pulse: 0,
        min_pulse: 0,
        max_pulse: 0,
    });
    const [BPPieChart, setBPPieChart] = useState({
        mean_pulse: 0,
        min_pulse: 0,
        max_pulse: 0,
    });
    
    const [BPGraph, setBPGraph] = useState();
    
    const [additionalAttributes, setAdditionalAttributes] = useState([]);

    const getPatientAttribute = async (organizationToken) => {
      try {
        const response = await axios.get(
          backendlink + "doctor/getpatientattribute",
          {
            params: {
              organizationToken: organizationToken,
              userToken: location.state.patientToken,
            },
            headers: {
              token: user.token,
            },
          }
        );

        if (
          response?.data?.errorno === 0 &&
          response?.data?.result?.length > 0
        ) {
          const mappedAttributes = response?.data?.result?.map((attribute) => {
            return {
              label: attribute.name,
              value: attribute.value,
              dataField: attribute.code,
            };
          });

          setAdditionalAttributes(mappedAttributes);
        } else {
          console.log(response?.data?.errmessage);
        }
      } catch (error) {
        console.error(error);
      }
    };

    function pressEdit() {
        navigate('/doctor-patient-edit', { state: { patientToken: patientToken } });
    }

    function pressChangePassword() {
        navigate('/doctor-patient-changepassword', { state: { patientToken: patientToken } });
    }

    function pressLog() {
        navigate('/doctor-patient-log', { state: { patientToken: patientToken } });
    }

    function pressMessage() {
        navigate('/doctor-patient-message', { state: { patientToken: patientToken } });
    }


    useEffect(() => {
        // console.log(location.state)

        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);

            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'doctor/getpatient',
                        {
                            doctortoken: user.userToken,
                            patienttoken: location.state.patientToken
                        },
                        {
                            headers: {
                                token: user.token
                            }
                        });

                    if (response.data.errorno == 0) {
                        // console.log(response.data.result)

                        const outputArray = response.data.result.map(item => {
                            var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')
                            var countryCodeString = item.countryCode? item.countryCode.replace(/[^0-9+]/g, '') : ""
                            var phoneForOtpString = item.phoneForOtp? item.phoneForOtp.replace(/[^0-9+]/g, '') : ""
                            getPatientAttribute(item.linkedcustomertoken);

                            setPatient({
                                name: item.name,
                                linkedCustomerToken: item.linkedcustomertoken,
                                linkedCustomerName: item.linkedcustomer,
                                address: item.description0,
                                contactPerson: item.description1,
                                email: item.email,
                                phone: item.phone,
                                countryCode: String(countryCodeString),
                                phoneForOtp: String(phoneForOtpString),
                                loginEmail: item.username,
                                status: statusString,
                                ...additionalAttributes.reduce((acc, field) => {
                                    return { ...acc, [field.dataField]: field.value };
                                  }, {}),
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();

        }
    }, [user, fetchTrigger]);


    useEffect(() => {
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            const fetchData2 = async () => {
                try {
                    const response = await axios.post(
                        backendlink + 'doctor/getbpdata',
                        {
                            doctortoken: user.userToken,
                            patientToken: location.state.patientToken,
                            duration: selectedDuration,
                        },
                        {
                            headers: {
                                token: user.token,
                            },
                        }
                    );

                    if (response.data.result.errorno == 0) {
                        setBPTable({
                            mean_systolic_blood_pressure: response.data.result.result.mean_systolic_blood_pressure,
                            min_systolic_blood_pressure: response.data.result.result.min_systolic_blood_pressure,
                            max_systolic_blood_pressure: response.data.result.result.max_systolic_blood_pressure,
                            mean_diastolic_blood_pressure: response.data.result.result.mean_diastolic_blood_pressure,
                            min_diastolic_blood_pressure: response.data.result.result.min_diastolic_blood_pressure,
                            max_diastolic_blood_pressure: response.data.result.result.max_diastolic_blood_pressure,
                            mean_pulse: response.data.result.result.mean_pulse,
                            min_pulse: response.data.result.result.min_pulse,
                            max_pulse: response.data.result.result.max_pulse,
                        });

                        setBPPieChart({
                            proportion_condition_1: response.data.result.result.proportion_condition_1,
                            proportion_condition_2: response.data.result.result.proportion_condition_2,
                            proportion_condition_3: response.data.result.result.proportion_condition_3,
                        });

                        setBPGraph(response.data.result.result.datapoints);
                    } 
                    else if (response.data.result.errorno == 40) {
                    }
                    else {
                        console.log(response)

                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    alert(error);
                }
            };

            fetchData2();
        }
    }, [selectedDuration]);

    SciChartSurface.useWasmFromCDN();

    useEffect(() => {

        const renderLineChart = async () => {
            const { wasmContext, sciChartSurface } = await SciChartSurface.createSingle("lineChart", {
                widthAspect: 1.5,
                heightAspect: 1,
                theme: new SciChartJsNavyTheme()
            });

            sciChartSurface.background = "#F9F9FF";

            // Create an XAxis with DateTimeNumericAxis
            const xAxis = new DateTimeNumericAxis(wasmContext, {
                axisTitle: "Date",
                cursorLabelFormat: ENumericFormat.Date_DDMMHHMM, // Use a predefined format
                drawLabels: false,
                majorGridLineStyle: { strokeThickness: 2 },
                minorGridLineStyle: { strokeThickness: 0 },
                majorTickLineStyle: { strokeThickness: 2 },
                minorTickLineStyle: { strokeThickness: 0 },
            });


            sciChartSurface.xAxes.add(xAxis);

            // Create a YAxis (unchanged)
            const yAxis = new NumericAxis(wasmContext, {
                autoRange: EAutoRange.Never,
                visibleRange: { min: 0, max: 150 },
                axisAlignment: EAxisAlignment.Left,
                labelPrecision: 0, // Ensure labels are integers
                majorGridLineStyle: { strokeThickness: 2 },
                minorGridLineStyle: { strokeThickness: 0 },
                majorTickLineStyle: { strokeThickness: 2 },
                minorTickLineStyle: { strokeThickness: 0 },

            });
            sciChartSurface.yAxes.add(yAxis);

            const systolicData = new XyDataSeries(wasmContext, { dataSeriesName: "Systolic BP" });
            const diastolicData = new XyDataSeries(wasmContext, { dataSeriesName: "Diastolic BP" });

            // Append data points to the series
            BPGraph.forEach((point) => {
                // Combine record_date and record_time into a JavaScript Date object
                const dateTime = Math.floor(new Date(`${point.record_date}T${point.record_time}`).getTime() / 1000); // Convert to seconds
                systolicData.append(dateTime, point.systolic_blood_pressure);
                diastolicData.append(dateTime, point.diastolic_blood_pressure);
            });

            sciChartSurface.renderableSeries.add(
                new FastLineRenderableSeries(wasmContext, {
                    dataSeries: systolicData,
                    stroke: "#FF5733",
                    strokeThickness: 2,
                })
            );

            sciChartSurface.renderableSeries.add(
                new FastLineRenderableSeries(wasmContext, {
                    dataSeries: diastolicData,
                    stroke: "#33FF57",
                    strokeThickness: 2,
                })
            );

            // Add a CursorModifier with custom tooltip
            const cursorModifier = new CursorModifier({
                showTooltip: true, // Enable tooltips
                showAxisLabels: false, // Disable axis labels in the tooltip
                showXLine: false, // Show vertical crosshair line
                showYLine: false, // Show horizontal crosshair line
                hitTestRadius: 10, // How close to a datapoint to show the tooltip
                axisLabelFill: "#b36200", // Background color for axis labels
                axisLabelStroke: "#fff", // Text color for axis labels
                crosshairStroke: "#ff6600", // Color for crosshair lines
                crosshairStrokeThickness: 1, // Thickness of crosshair lines
                tooltipContainerBackground: "#000", // Background color of tooltip
                tooltipTextStroke: "#ff6600", // Text color of tooltip

                tooltipDataTemplate: (seriesInfos, tooltipTitle) => {
                    // each element in this array = 1 line in the tooltip
                    const lineItems = [];
                    seriesInfos.forEach(si => {
                        if (si.isHit) {
                            const date = new Date(si.xValue * 1000); // Convert seconds to milliseconds
                            const formattedDate = date.toLocaleDateString() + " " + date.toLocaleTimeString();

                            if (si.seriesName == "Systolic BP") {
                                lineItems.push(`Date: ${formattedDate}`);
                                lineItems.push(`Systolic BP: ${Math.round(si.yValue)}`); // Convert to integer
                            }
                            else {
                                lineItems.push(`Date: ${formattedDate}`);
                                lineItems.push(`Diastolic BP: ${Math.round(si.yValue)}`); // Convert to integer
                            }
                        }
                    });
                    return lineItems;
                }



            });

            sciChartSurface.chartModifiers.add(cursorModifier);
        };

        if (BPGraph?.length > 0) {
            renderLineChart();
        }

    }, [BPGraph]);


    useEffect(() => {
        const renderDonutChart = async () => {
            // Create the SciChartPieSurface
            const customTheme = { ...new SciChartJSLightTheme() };
            customTheme.sciChartBackground = "#FFFFFF"; // Set background to white

            const sciChartPieSurface = await SciChartPieSurface.create("donutChart1", {
                theme: customTheme,
                pieType: EPieType.Donut,
                holeRadius: 0.6,
                animate: false,
                showLegend: false,
            });

            // Create pie segments
            const chart1pieSegment1 = new PieSegment({
                value: BPPieChart.proportion_condition_1,
                text: "Category A",
                color: "#FF5733", // Color for the segment
                showLabel: false

            });

            const chart1pieSegment2 = new PieSegment({
                value: 100 - BPPieChart.proportion_condition_1,
                color: "#CCCCCC", // Color for the segment
                showLabel: false
            });


            // Add segments to the pieSegments collection
            sciChartPieSurface.pieSegments.add(chart1pieSegment1, chart1pieSegment2);

            const sciChartPieSurface2 = await SciChartPieSurface.create("donutChart2", {
                theme: customTheme,
                pieType: EPieType.Donut,
                holeRadius: 0.6, // Adjust the hole size
                animate: false,
                showLegend: false
            });

            // Create pie segments
            const chart2pieSegment1 = new PieSegment({
                value: BPPieChart.proportion_condition_2,
                text: "Category A",
                color: "#FF5733", // Color for the segment
                showLabel: false

            });

            const chart2pieSegment2 = new PieSegment({
                value: 100 - BPPieChart.proportion_condition_2,
                text: "Category A",
                color: "#CCCCCC", // Color for the segment
                showLabel: false


            });

            // Add segments to the pieSegments collection
            sciChartPieSurface2.pieSegments.add(chart2pieSegment1, chart2pieSegment2);

            const sciChartPieSurface3 = await SciChartPieSurface.create("donutChart3", {
                theme: customTheme,
                pieType: EPieType.Donut,
                holeRadius: 0.6, // Adjust the hole size
                animate: false,
                showLegend: false

            });

            // Create pie segments
            const chart3pieSegment1 = new PieSegment({
                value: BPPieChart.proportion_condition_3,
                text: "Category A",
                color: "#FF5733", // Color for the segment
                showLabel: false

            });

            const chart3pieSegment2 = new PieSegment({
                value: 100 - BPPieChart.proportion_condition_3,
                text: "Category A",
                color: "#CCCCCC", // Color for the segment
                showLabel: false


            });

            // Add segments to the pieSegments collection
            sciChartPieSurface3.pieSegments.add(chart3pieSegment1, chart3pieSegment2);
        };

        renderDonutChart();

    }, [BPPieChart]);


    const readonlyptions = { readOnly: 'true' };

    return (
        <div className="card-container" style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>

            {/* Patient Information Card */}
            <div className="card" style={{ flex: "1 1 45%" }}>
                <h2>Patient Information</h2>
                <div className="user-info">
                    <div className="user-info-item">
                        <span className="label">Name:</span>
                        <span className="value">{patient.name}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Linked Organization:</span>
                        <span className="value">{patient.linkedCustomerName}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Address:</span>
                        <span className="value">{patient.address}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Contact Person:</span>
                        <span className="value">{patient.contactPerson}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Contact Email:</span>
                        <span className="value">{patient.email}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Login Email:</span>
                        <span className="value">{patient.loginEmail}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Status:</span>
                        <span className="value">{patient.status}</span>
                    </div>
                    <div className="user-info-item">
                        <span className="label">Login Phone Number:</span>
                        <span className="value">{patient.phoneForOtp ? `+${patient.countryCode}-${patient.phoneForOtp}` : 'NA'}</span>
                    </div>
                    {additionalAttributes.map((attribute) => (
                        <div key={attribute.dataField} className="user-info-item">
                            <span className="label">{attribute.label}:</span>
                            <span dangerouslySetInnerHTML={{ __html: attribute.value.replace(/\n/g, "<br />") }} />
                        </div>
                    ))}
                    {/* Add more fields as needed */}
                </div>
            </div>

            {/* Blood Pressure Summary Card */}
            <div className="card" style={{ flex: "1 1 45%" }}>
                <h2>Blood Pressure Summary</h2>
                {/* Duration Select Box */}
                <div style={{ width: "100%", marginBottom: "20px" }}>
                    <label htmlFor="duration-select" style={{ marginRight: "10px" }}>Select Duration:</label>
                    <select id="duration-select" value={selectedDuration} onChange={handleDurationChange}>
                        <option value="0">1 Week</option>
                        <option value="1">1 Month</option>
                        <option value="2">3 Months</option>
                        <option value="3">6 Months</option>
                        <option value="4">1 Year</option>
                    </select>
                </div>
                {BPGraph &&
                    <div className="summary-row" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        {/* First row: Table */}
                        <div style={{ width: "100%" }}>
                            <table className="blood-pressure-table" style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}></th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Systolic Blood Pressure (mmHg)</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Diastolic Blood Pressure (mmHg)</th>
                                        <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "left" }}>Pulse (bpm)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Max</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.max_systolic_blood_pressure}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.max_diastolic_blood_pressure}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.max_pulse}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Min</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.min_systolic_blood_pressure}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.min_diastolic_blood_pressure}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.min_pulse}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>Average</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.mean_systolic_blood_pressure}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.mean_diastolic_blood_pressure}</td>
                                        <td style={{ border: "1px solid #ddd", padding: "8px" }}>{BPTable.mean_pulse}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/* Second row: Donut charts */}
                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center" }}>
                                <div className='donuttitle'>
                                    Systolic Blood Pressure &lt;130mmHg <br /> and <br /> diastolic blood pressure &lt;80mmHg
                                </div>
                                <div id="donutChart1" style={{ width: "150px", height: "150px" }}></div>
                                <div className='donutpercentage'>
                                    {BPPieChart.proportion_condition_1} %
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className='donuttitle'>
                                    Systolic Blood Pressure &lt;140mmHg <br /> and <br /> diastolic blood pressure &lt;90mmHg
                                </div>
                                <div id="donutChart2" style={{ width: "150px", height: "150px" }}></div>
                                <div className='donutpercentage'>
                                    {BPPieChart.proportion_condition_2} %
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className='donuttitle'>
                                    Systolic Blood Pressure &gt;=160mmHg <br /> and <br /> diastolic blood pressure &gt;=100mmHg
                                </div>
                                <div id="donutChart3" style={{ width: "150px", height: "150px" }}></div>
                                <div className='donutpercentage'>
                                    {BPPieChart.proportion_condition_3} %
                                </div>
                            </div>
                        </div>
                        {/* Third row: Line chart */}
                        <div id="lineChart" style={{ width: "100%", height: "300px", marginTop: "20px" }}></div>
                    </div>
                }
            </div>
        </div>

    )
}
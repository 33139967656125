import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './PartnerBillingDatagrid.css';

export default function PartnerBillingDatagrid() {
    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [formData, setformData] = useState({ id: '' });
    const formDataRef = useRef(null)

    const [exportCustomerItems, setExportCustomerItems] = useState([]);

    const [loadingExport, setLoadingExport] = useState(false);

    function handleRowClick(e) {

    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'partner/getpartnercustomers',
                {
                    usertoken: user.userToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    // console.log(response.data.result)

                    var outputArray = []
                    var exportArray = []

                    exportArray.push({ id: "", text: "All Organization"})

                    response.data.result[0].forEach(item => {
                        var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                        outputArray.push( { customerName: item.username, customerID: item.id, status: statusString, linkedPartner: item.partnerName, companyname: item.companyname, address: item.companyaddress, contactPerson: item.contactperson, phone: item.phone, email: item.email, customerToken: item.userToken, currentMonthUsage: item.currentMonthUsage })
                        exportArray.push({ id: item.userToken, text: item.companyname})
                    });

                    setUsers(outputArray)
                    setExportCustomerItems(exportArray)
                }
                else if (response.data.errorno == 330) {
                    setUsers([])
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

        var dateStartdate = new Date(Date.now() - 86400000)
        var dateEnddate = new Date()

        setformData({ exportCustomerToken: '', startDate: dateStartdate, endDate: dateEnddate })
    }, [user]);

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    return (
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClick}>
                <Column dataField='customerID' caption='Organization ID' alignment='left' />
                <Column dataField='companyname' caption='Company Name' />
                <Column dataField='currentMonthUsage' caption='Current Month Usage' />
                <Column dataField='contactPerson' caption='Contact Person' />
                <Column dataField='phone' caption='Phone Number' />
                <Column dataField='email' caption='Contact Email' />

                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>

        </React.Fragment>
    )
}
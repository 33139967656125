import React from 'react';
import { ChangePasswordForm } from '../../components';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

export default function ChangePasswordPage() {
    const breadcrumbItems = [
        { label: 'Home', path: '/', active: false },
        { label: 'Change Password', path: '#', active: true }
          ];

    return (
        <React.Fragment>
            <Breadcrumb title="Change Password" items={breadcrumbItems} />
            <div className={'content-block dx-card responsive-paddings'}>
                <ChangePasswordForm/>
            </div>
        </React.Fragment>
    )
}
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './CustomerPatientLogDatagrid.css';

export default function CustomerPatientLogDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [patientToken, setPatientToken] = useState("")

    const location = useLocation();

    const [linkedCustomerName, setLinkedCustomerName] = useState('');
    const [linkedCustomerID, setLinkedCustomerID] = useState('');
    const [linkedDoctorName, setLinkedDoctorName] = useState('');
    const [patientName, setPatientName] = useState('');

    const [logs, setLogs] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.post(backendlink + 'customer/getpatient',
            {
                usertoken: user.userToken,
                patienttoken: location.state.patientToken
            },
            {
                headers: {
                    token: user.token
                }
            });

            if (response.data.errorno == 0) {
                // console.log(response.data.result)

                const outputArray = response.data.result.map(item => {
                    console.log(item)

                    setLinkedCustomerName(item.linkedcustomer)
                    setLinkedCustomerID(item.linkedcustomerid)
                    setLinkedDoctorName(item.linkeddoctor)
                    setPatientName(item.name)

                    fetchLog()
                });
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };

    const fetchLog = async () => {
        try {
            const response = await axios.post(backendlink + 'customer/getpatientlogs',
            {
                usertoken: user.userToken,
                patienttoken: location.state.patientToken
            },
            {
                headers: {
                    token: user.token
                }
            });

            if (response.data.errorno == 0) {
                // console.log(response.data.result)

                const outputArray = response.data.result[0].map(item => {
                    var datelogTimestamp = new Date(item.logTimestamp)
                    var logTimestampDate = datelogTimestamp.getFullYear() + '-' + (((datelogTimestamp.getMonth()+1) < 10) ? ('0' + (datelogTimestamp.getMonth()+1)) : (datelogTimestamp.getMonth()+1)) + '-' + (((datelogTimestamp.getDate()) < 10) ? ('0' + (datelogTimestamp.getDate())) : (datelogTimestamp.getDate()));
                    var logTimestampTime = (((datelogTimestamp.getHours()) < 10) ? ('0' + (datelogTimestamp.getHours())) : (datelogTimestamp.getHours())) + ':' + (((datelogTimestamp.getMinutes()) < 10) ? ('0' + (datelogTimestamp.getMinutes())) : (datelogTimestamp.getMinutes())) + ':' + (((datelogTimestamp.getSeconds()) < 10) ? ('0' + (datelogTimestamp.getSeconds())) : (datelogTimestamp.getSeconds()));

                    return { category: item.logActivity, logDate: logTimestampDate, logTime: logTimestampTime, logDescription: item.logDescription };
                }).reverse();
                setLogs(outputArray)
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            // console.log(error);
            alert(error);
        }
    };

    useEffect(() => {
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);
            // console.log(user.token)
            // console.log(user.userToken)
            // console.log(location.state.patientToken)

            fetchData();
        }
    }, [user]);

    return (
        <React.Fragment>
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Organization:</th>
                        <td>{linkedCustomerName}</td>
                        <th>Organization ID:</th>
                        <td>{linkedCustomerID}</td>
                        <th>Doctor:</th>
                        <td>{linkedDoctorName}</td>
                        <th>Patient:</th>
                        <td>{patientName}</td>
                    </tr>
                </thead>
            </table>

            <DataGrid dataSource={logs}>
                <Column dataField='category' caption='Activity' />
                <Column dataField='logDate' caption='Date' />
                <Column dataField='logTime' caption='Time' />
                <Column dataField='logDescription' caption='Details' />
                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>
        </React.Fragment>
    )
}
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';
import GroupCaption from '../../pages/group-caption/GroupCaption';

export default function CustomerDoctorAddForm() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ name: '', description0: '', address: '', contactPerson: '', email: '', phone: '', loginEmail: '', status: true });
    const formDataRef = useRef(null)

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    const [linkedCustomerItems, setLinkedCustomerItems] = useState([]);

    const groupCaptionNamedRender = (iconName) => {
        const groupCaptionRender = (data) => (
          <GroupCaption
            iconName={iconName}
            {...data}
          />
        );
        return groupCaptionRender;
    };

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log("dsadasda", formData)
        // console.log('aaaaa', customerToken)

        if (!validateEmail(formData.email)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Email format error",
            });
        } else if (!validateEmail(formData.loginEmail)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Login Email format error",
            });
        } else {
            setLoading(true);

            try {
                const response = await axios.post(backendlink + 'customer/createdoctor',
                {
                    usertoken: user.userToken,
                    name: formData.name,
                    description0: formData.description0,
                    companyaddress: formData.address,
                    contactperson: formData.contactPerson,
                    email: formData.email,
                    phone: formData.phone,
                    username: formData.loginEmail,
                    password: formData.password,
                    confirmPassword: formData.confirmPassword,
                    isEnabled: formData.status
                }, 
                {
                    headers: {
                        token: user.token
                    }        
                }).then((res)=>{
                    setLoading(false);

                    if (res.data.errorno == 0){
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'success',
                            // message: 'New partner has been added',
                            message: 'Doctor created',
                        });    

                        setformData({ name: '',  description0: '', address: '', contactPerson: '', email: '', phone: '', loginEmail: '', status: true })
                    } else{
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'error',
                            message: res.data.errmessage,
                        });    
                    }
                });
            } catch (error) {
                setLoading(false);

                // console.log(error);
                alert(error);
            }
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        setformData({ name: '', description0: '', address: '', contactPerson: '', email: '', phone: '', loginEmail: '', status: true })
    }, [user]);

    const passwordOptions = { mode: "password" };

    var statusItems = [
        { id: true, text: 'Active'},
        { id: false, text: 'Inactive'},
    ];

    return (
      <form onSubmit={onSubmit}>
        <Form
          formData={formData}
          ref={formDataRef}
          disabled={false}
          colCount={4}
        >
          <GroupItem
            iconName="user"
            captionRender={groupCaptionNamedRender("user")}
            caption="Personal Data"
            colSpan={4}
          >
            <GroupItem colCount={2}>
              <Item dataField={"name"} editorType={"dxTextBox"}>
                <Label text="Doctor Name" />
              </Item>

              <Item dataField={"description0"} editorType={"dxTextBox"}>
                <Label text="Description" />
              </Item>

              <Item dataField={"address"} editorType={"dxTextBox"}>
                <Label text="Address" />
              </Item>

              <Item
                dataField={"status"}
                editorType={"dxSelectBox"}
                editorOptions={{
                  items: statusItems,
                  displayExpr: "text",
                  valueExpr: "id",
                }}
              >
                <Label text="Status" />
              </Item>
            </GroupItem>
          </GroupItem>

          <GroupItem
            captionRender={groupCaptionNamedRender("card")}
            caption="Login Data"
            colSpan={2}
          >
            <GroupItem colCount={1}>
              <Item dataField={"loginEmail"} editorType={"dxTextBox"}>
                <Label text="Login Email" />
              </Item>

              <GroupItem colCount={4}>
                <Item
                  colSpan={2}
                  dataField={"password"}
                  editorType={"dxTextBox"}
                  editorOptions={passwordOptions}
                >
                  <Label text="Password" />
                </Item>

                <Item
                  colSpan={2}
                  dataField={"confirmPassword"}
                  editorType={"dxTextBox"}
                  editorOptions={passwordOptions}
                >
                  <Label text="Confirm Password" />
                </Item>
              </GroupItem>
            </GroupItem>
          </GroupItem>

          <GroupItem
            captionRender={groupCaptionNamedRender("home")}
            caption="Emergency Contact Information"
            colSpan={2}
          >
            <GroupItem colCount={1}>
              <Item dataField={"contactPerson"} editorType={"dxTextBox"}>
                <Label text="Contact Person" />
              </Item>

              <Item dataField={"phone"} editorType={"dxTextBox"}>
                <Label text="Contact Number" />
              </Item>

              <Item dataField={"email"} editorType={"dxTextBox"}>
                <Label text="Contact Email" />
              </Item>
            </GroupItem>
          </GroupItem>

          <ButtonItem colSpan={4} cssClass="full-width-button">
            <ButtonOptions
              width={"100%"}
              type={"default"}
              useSubmitBehavior={true}
            >
              <span className="dx-button-text">
                {loading ? (
                  <LoadIndicator
                    width={"24px"}
                    height={"24px"}
                    visible={true}
                  />
                ) : (
                  "Add New Doctor"
                )}
              </span>
            </ButtonOptions>
          </ButtonItem>
        </Form>

        <Toast
          visible={toastConfig.isVisible}
          message={toastConfig.message}
          type={toastConfig.type}
          onHiding={onHiding}
          displayTime={2000}
        />
      </form>
    );
}
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
    GroupItem
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';

export default function PartnerPatientInfoList() {
    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const [patientToken, setPatientToken] = useState("")

    const location = useLocation();

    // const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ name: '', linkedCustomerToken: '', linkedCustomerName: '', linkedDoctorToken: '', linkedDoctorName: '', address: '', contactPerson: '', email: '', phone: '', loginEmail: '', status: '' });
    const formDataRef = useRef(null)

    function pressEdit() {
        navigate('/partner-patient-edit', { state: { patientToken: patientToken } });
    }

    function pressChangePassword() {
        navigate('/partner-patient-changepassword', { state: { patientToken: patientToken } });
    }

    function pressLog() {
        navigate('/partner-patient-log', { state: { patientToken: patientToken } });
    }

    useEffect(() => {
        // console.log(location.state)
        
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);
            // console.log(user.token)
            // console.log(user.userToken)
            // console.log(location.state.patientToken)
            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'partner/getpatient',
                    {
                        usertoken: user.userToken,
                        patienttoken: location.state.patientToken
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });

                    if (response.data.errorno == 0) {
                        // console.log(response.data.result)

                        const outputArray = response.data.result.map(item => {
                            // console.log(item)

                            var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                            setformData({
                                name: item.name, 
                                linkedCustomerToken: item.linkedcustomertoken, 
                                linkedCustomerName: item.linkedcustomer, 
                                linkedDoctorToken: item.parentUserToken, 
                                linkedDoctorName: item.linkeddoctor, 
                                address: item.description0,
                                contactPerson: item.description1, 
                                email: item.email, 
                                phone: item.phone, 
                                loginEmail: item.username,
                                status: statusString
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            fetchData();
        }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };

    return (
        <Form formData={formData} ref={formDataRef} disabled={false}>
            <GroupItem cssClass="form-group" colCount={3}>
                <ButtonItem>
                    <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={pressEdit}
                    >
                    <span className="dx-button-text">
                        Edit
                    </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem>
                    <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={pressChangePassword}
                    >
                    <span className="dx-button-text">
                        Change Password
                    </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem>
                    <ButtonOptions
                    width={'100%'}
                    type={'default'}
                    onClick={pressLog}
                    >
                    <span className="dx-button-text">
                        Log
                    </span>
                    </ButtonOptions>
                </ButtonItem>
            </GroupItem>

            <Item
                dataField={'name'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Patient Name' />
            </Item>

            <Item
                dataField={'linkedCustomerName'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Linked Organization' />
            </Item>

            <Item
                dataField={'linkedDoctorName'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Linked Doctor' />
            </Item>


            <Item
                dataField={'address'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Address' />
            </Item>

            <Item
                dataField={'contactPerson'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Contact Person' />
            </Item>

            <Item
                dataField={'phone'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Phone Number' />
            </Item>

            <Item
                dataField={'email'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Contact Email' />
            </Item>

            <Item
                dataField={'loginEmail'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Login Email' />
            </Item>

            <Item
                dataField={'status'}
                editorType={'dxTextBox'}
                editorOptions={readonlyptions}
            >
                <Label text='Status' />
            </Item>
        </Form>
    )
}
import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';
import { SITEKEY } from '../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import './ForgetPasswordForm.scss';

export default function ForgetPasswordForm() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ username: '' });
    const formDataRef = useRef(null)

    const [captchaVerified, setCaptchaVerified] = useState(false);
    const recaptchaRef = useRef(null);

    const onCaptchaChange = (value) => {
        setCaptchaVerified(!!value);
    };

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        try {
            const response = await axios.post(backendlink + 'user/getotp',
                {
                    username: formData.username,
                },
                {
                    headers: {

                    }
                }).then((res) => {
                    setLoading(false);

                    if (res.data.errorno == 0) {
                        // console.log(res.data)

                        navigate('/validateotp', { state: { username: formData.username, userToken: res.data.result[0].userToken } });
                    } else {
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'error',
                            message: res.data.errmessage,
                        });
                    }
                });
        } catch (error) {
            setLoading(false);

            // console.log(error);
            alert(error);
        }
    };

    return (
        <form className={'login-form'} onSubmit={onSubmit}>
            <Form formData={formData} ref={formDataRef} disabled={loading}>
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Login Email' />
                </Item>
                <Item>
                    <div className="recaptcha-container">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={SITEKEY}
                            onChange={onCaptchaChange}
                        />
                    </div>
                </Item>
                <ButtonItem cssClass="custom-button-padding">
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                        disabled={!captchaVerified || loading}
                        className="custom-button-padding"
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                    : 'Get Verification Code'
                            }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                <Item>
                    <div className={'link'}>
                        <Link to={'/login'}>Return to Sign In</Link>
                    </div>
                </Item>
            </Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
        </form>
    );
}
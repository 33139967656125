import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { DoctorPatientBPDatagrid, DoctorPatientChangepasswordForm, DoctorPatientECGDatagrid, DoctorPatientEditForm, DoctorPatientInfoList, DoctorPatientMessageDatagrid, DoctorPatientOtherMessageDatagrid} from '../../components';
  import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";

export default function DoctorPatientInfoPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
    const [title, setTitle] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);
    const [fetchTrigger, setFetchTrigger] = useState(0);

    const breadcrumbItemsBase = [
        { label: 'Home', path: '/', active: false },
        { label: 'Patient List', path: '/doctor-patient', active: false }
    ];

    const handleDataRetrieved = (data) => {
        setTitle(data[0]);
        setBreadcrumbItems(data[1]);
    };

    const tabTitle = [
        "Patient Info", 
        // "Edit Patient Profile",
        // "Change Password",
        "Heart Sound Records",
        "ECG Records",
        "Lung Sound Records",
        "Blood Pressure Records"
    ];

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;
        var isReselected = false;

        if (itemIndex == tabSelectedIndex) {
            isReselected = true
        }

        setTabSelectedIndex(itemIndex)
        setTitle(tabTitle[itemIndex]);
        setBreadcrumbItems(breadcrumbItemsBase.concat( { label: tabTitle[itemIndex], path: '#', active: true }));
        setFetchTrigger((prevTrigger) => prevTrigger + 1);
    //    if (itemIndex == 0 && isReselected == true) {
    //        navigate('/doctor-patient?tabSelectedIndex=0');
    //    }
    }

    useEffect(() => {
        if (user.usertype == "Doctor") {
            setTitle(tabTitle[0]);
            setBreadcrumbItems(breadcrumbItemsBase.concat( { label: tabTitle[0], path: '#', active: true }));
        } else {
            navigate('/');
        }
    }, []);

    return (
        <React.Fragment>
            <Breadcrumb title={title} items={breadcrumbItems} />

            <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true} selectedIndex={tabSelectedIndex} onTitleClick={onTitleClick}>
                <Item title={tabTitle[0]}> 
                    <DoctorPatientInfoList onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex} fetchTrigger={fetchTrigger} />
                </Item>

                {/* <Item title={tabTitle[1]}> 
                    <DoctorPatientEditForm onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex}  />
                </Item>
    
                <Item title={tabTitle[2]}> 
                    <DoctorPatientChangepasswordForm onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex}  />
                </Item> */}

                <Item title={tabTitle[1]}> 
                    <DoctorPatientMessageDatagrid onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex}  />
                </Item>

                <Item title={tabTitle[2]}> 
                    <DoctorPatientECGDatagrid onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex}  />
                </Item>

                <Item title={tabTitle[3]}> 
                    <DoctorPatientOtherMessageDatagrid onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex}  />
                </Item>

                <Item title={tabTitle[4]}> 
                    <DoctorPatientBPDatagrid onDataRetrieved={handleDataRetrieved} tabSelectedIndex={tabSelectedIndex}  />
                </Item>
               
            </TabPanel>
        </React.Fragment>
    );
}
import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import axios from "axios";
import { backendlink } from '../config';
import Cookies from 'js-cookie';
import { Popup } from 'devextreme-react';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    const signIn = useCallback(async (email, password) => {
        try {
            const result = await axios.post(backendlink + 'user/signin', {
                username: email,
                password: password
            });
            if (result.data.errorno == 0) {
                setUser(result.data.result[0]);
                Cookies.set("vitogram_integrated_user", JSON.stringify(result.data.result[0]), { expires: 7 });
                return { "success": true, "error": null, "userObject": result.data.result[0] };
            } else {
                return { "success": false, "error": result.data.errmessage, "userObject": null };
            }
        } catch (error) {
            console.log(error);
            return { "success": false, "error": error, "userObject": null };
        }
    }, []);

    const signOut = useCallback(() => {
        setUser(undefined);
        Cookies.remove("vitogram_integrated_user");
    }, []);

    return (
        <AuthContext.Provider value={{ user, setUser, signIn, signOut, loading }} {...props}>
            {props.children}
        </AuthContext.Provider>
    );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
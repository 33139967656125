import React, { useEffect, useRef } from 'react';
import {
    EAutoRange,
    EDragMode,
    FastLineRenderableSeries,
    NumericAxis,
    SciChartSurface,
    XAxisDragModifier,
    XyDataSeries,
    YAxisDragModifier,
    ZoomExtentsModifier,
    ZoomPanModifier,
    SciChartJsNavyTheme, // Import the theme
    SciChartJSLightTheme,
    XyScatterRenderableSeries,
    EAxisAlignment,
    DataPointInfo,
    NumericLabelProvider,
    ENumericFormat
} from "scichart";

const SciChartExample = ({ ecgData, uid, datapointinfo }) => {
    const chartRef = useRef(null);
    const sciChartSurfaceRef = useRef(null);

    SciChartSurface.useWasmFromCDN();

    useEffect(() => {
        const drawChart = async () => {
            try {
                // Create a SciChartSurface with the Navy theme
                const { wasmContext, sciChartSurface } = await SciChartSurface.createSingle('ecg-' + uid, {
                    theme: new SciChartJsNavyTheme()
                });

                sciChartSurfaceRef.current = sciChartSurface;
                console.log("SciChartSurface created");

                sciChartSurface.background = "white";

                // Create an XAxis with fixed range and tick spacing
                const xAxis = new NumericAxis(wasmContext, {
                    autoRange: EAutoRange.Never, // Disable auto-range
                    visibleRange: { min: 0, max: 3000 }, // Set a fixed visible range (adjust as needed)
                    axisTitle: "Time (ms)",
                    axisTitleStyle:{
                        fontSize: 12,
                        color: "#181C23"
                    },
                    labelStyle: {
                        color: "#181C23" // Change the color of the axis numbers
                    },
                    majorGridLineStyle: { strokeThickness: 2, color: "#FFDAD6" },
                    minorGridLineStyle: { strokeThickness: 1, color: "#FFDAD6" },
                    majorTickLineStyle: { strokeThickness: 2, color: "#FFDAD6" },
                    minorTickLineStyle: { strokeThickness: 1, color: "#FFDAD6" },
                    autoTicks: false, // Set autoTicks to false to manually control deltas
                    majorDelta: 200,
                    minorDelta: 40,
                    labelPrecision: 0,
                    labelFormat: ENumericFormat.Decimal
                });
                sciChartSurface.xAxes.add(xAxis);

                const firstValue = ecgData[0];
                const visibleRangeMin = firstValue - 2.5;
                const visibleRangeMax = firstValue + 2.5;

                // Create a YAxis (unchanged)
                const yAxis = new NumericAxis(wasmContext, {
                    autoRange: EAutoRange.Never,
                    // visibleRange: { min: -2, max: 2 }, // Set a fixed visible range limit
                    visibleRange: { min: visibleRangeMin, max: visibleRangeMax },
                    axisTitle: "Amplitude (mV)",
                    axisTitleStyle:{
                        fontSize: 12,
                        color: "#181C23"
                    },
                    labelStyle: {
                        color: "#181C23" 
                    },
                    majorGridLineStyle: { strokeThickness: 2, color: "#FFDAD6" },
                    minorGridLineStyle: { strokeThickness: 1, color: "#FFDAD6" },
                    majorTickLineStyle: { strokeThickness: 2, color: "#FFDAD6" },
                    minorTickLineStyle: { strokeThickness: 1, color: "#FFDAD6" },
                    axisAlignment: EAxisAlignment.Left,
                    // autoTicks: false, // Set autoTicks to false to manually control deltas
                    // majorDelta: 0.5,
                    // minorDelta: 0.5
                });
                sciChartSurface.yAxes.add(yAxis);

                // Add some interactivity modifiers
                sciChartSurface.chartModifiers.add(
                    new ZoomPanModifier(),
                    new XAxisDragModifier({ dragMode: EDragMode.Panning }),
                    new YAxisDragModifier({ dragMode: EDragMode.Panning })
                );
                console.log("Chart modifiers added");

                // Create a DataSeries
                const dataSeries = new XyDataSeries(wasmContext, { containsNaN: false, isSorted: true });

                console.log(ecgData)

                // Populate the DataSeries with ECG data
                const xValues = ecgData.map((_, index) => index * 2); // Convert to milliseconds
                const yValues = ecgData;
                dataSeries.appendRange(xValues, yValues);
                console.log("DataSeries populated");

                // Create a FastLineRenderableSeries bound to the dataSeries and add to the chart
                const seriesColor = "black";
                sciChartSurface.renderableSeries.add(
                    new FastLineRenderableSeries(wasmContext, {
                        dataSeries,
                        strokeThickness: 2,
                        stroke: seriesColor,
                    })
                );
                console.log("RenderableSeries added");

                sciChartSurface.invalidateElement();

                return { wasmContext, sciChartSurface };
            } catch (error) {
                console.error("Error creating SciChartSurface:", error);
            }
        };

        drawChart();

        // Cleanup on component unmount
        return () => {
            if (sciChartSurfaceRef.current) {
                sciChartSurfaceRef.current.delete();
                sciChartSurfaceRef.current = null;
            }
        };
    }, []);

    const formatTime = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    return (
        // <div id={`ecg-${uid}`} style={{  justifyContent: 'center', alignItems: 'center' }}>
        //     <table className="custom-table" style={{ width: '100%', maxWidth: '500px' }}>
        //         <thead>
        //             <tr>
        //                 <th>Time:</th>
        //                 <td></td>
        //                 <th>BPM:</th>
        //                 <td></td>
        //             </tr>
        //         </thead>
        //     </table>
        //     <div ref={chartRef} style={{ width: '300px', height: '300px' }}></div>
        // </div>
        <div id={`ecgdata-${uid}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', marginLeft: '10px', height: '500px'}}>
            <div style={{ fontSize: '20px', fontWeight: 600, textAlign: 'left', width: '100%' }}>
                Selected ECG Graph
            </div>
            <table className="custom-table" style={{ width: '100%',  marginTop: '20px', textAlign: 'left' }}>
                <thead>
                    <tr>
                        <th>Time:</th>
                        <td>{formatTime(datapointinfo.start)} - {formatTime(datapointinfo.end)}</td>
                        <th>BPM:</th>
                        <td>{Math.round(datapointinfo.totalBpm)}</td>
                    </tr>
                </thead>
            </table>
            <div id={`ecg-${uid}`} ref={chartRef} style={{ width: '100%', height: '500px' }}></div>
        </div>
    );
};

export default SciChartExample;
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
  } from 'devextreme-react/data-grid';
  import React, { useState, useEffect } from 'react';
  import { useAuth } from '../../contexts/auth';
  import { Link, useNavigate } from 'react-router-dom';
  import TabPanel, { Item } from "devextreme-react/tab-panel";
  import axios from 'axios';
  import { backendlink } from '../../config';
  import { CustomerDoctorEditForm, CustomerDoctorChangepasswordForm, CustomerDoctorInfoList, CustomerPatientListDatagrid} from '../../components';
  import PersonIcon from "../../svg/Property 1=person_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 1.svg";
  import StethoscopeIcon from "../../svg/Property 1=stethoscope_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg";
  import Breadcrumb from '../../components/breadcrumb/Breadcrumb';

  export default function PartnerCustomerInfoPage() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0)
    const [title, setTitle] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    const breadcrumbItemsBase = [
        { label: 'Home', path: '/', active: false },
        { label: 'Doctor List', path: '/customer-doctor', active: false }
       
    ];

    const tabTitle = [
        "Doctor Information", 
        "Edit Doctor Profile",
        "Change Password"
    ];

    function onTitleClick(e) {
        var itemIndex = e.itemIndex;
        setTabSelectedIndex(itemIndex)
        setTitle(tabTitle[itemIndex]);
        setBreadcrumbItems(breadcrumbItemsBase.concat( { label: tabTitle[itemIndex], path: '#', active: true }));
    }

    useEffect(() => {
        if (user.usertype == "Customer") {
            setTitle(tabTitle[0]);
            setBreadcrumbItems(breadcrumbItemsBase.concat( { label: tabTitle[0], path: '#', active: true }));
        } else {
            navigate('/');
        }
    }, []);






    return (
        <React.Fragment>
            <Breadcrumb title={title} items={breadcrumbItems} />
            <TabPanel className={'content-block dx-card responsive-paddings'} swipeEnabled={false} scrollByContent={true} showNavButtons={true} onTitleClick={onTitleClick}>

                <Item title="Doctor Information"> 
                    <CustomerDoctorInfoList />
                </Item>

                <Item title="Edit Doctor Information"> 
                    <CustomerDoctorEditForm />
                </Item>
    

                <Item title="Change Password"> 
                    <CustomerDoctorChangepasswordForm />
                </Item>

            </TabPanel>
        </React.Fragment>
      );
  }
import { createRoot } from 'react-dom/client';
import DataGrid, {
    Column,
    Pager,
    Paging,
    Lookup,
} from 'devextreme-react/data-grid';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backendlink } from '../../config';
import DataSource from 'devextreme/data/data_source';



export default function DoctorPatientBPDatagrid() {

    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [patientToken, setPatientToken] = useState("");
    const [linkedCustomerName, setLinkedCustomerName] = useState('');
    const [linkedCustomerID, setLinkedCustomerID] = useState('');
    const [linkedDoctorName, setLinkedDoctorName] = useState('');
    const [patientName, setPatientName] = useState('');
    const [pageSize, setPageSize] = useState(5);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);


    const fetchMessage = async (loadOptions) => {
        try {
            const page = Math.floor(loadOptions.skip / loadOptions.take) + 1;
            const size = loadOptions.take;

            console.log("Load Options:", loadOptions);
            console.log("Page:", page, "Size:", size);

            const response = await axios.post(
                backendlink + 'doctor/getbplist',
                {
                    patientToken: location.state.patientToken,
                    page: page,
                    size: size,
                },
                {
                    headers: {
                        token: user.token,
                    },
                }
            );

            console.log(response)

            if (response.data.errorno === 0) {
                // Map the result to the format expected by the DataGrid
                const mappedData = response.data.result.result.map((item) => ({
                    id: item.id, // Unique identifier for the row
                    date: item.record_date, // Record date
                    time: item.record_time, // Record time
                    sBP: item.systolic_blood_pressure, // Systolic BP
                    dBP: item.diastolic_blood_pressure, // Diastolic BP
                    bpm: item.pulse, // Pulse
                }));

                // Update the total records count for pagination
                setTotalRecords(response.data.result.meta.total);

                return {
                    data: mappedData, // Data for the current page
                    totalCount: response.data.result.meta.total, // Total number of records
                };
            } else {
                throw new Error(response.data.errormessage);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };

    const [dataSource] = useState(new DataSource({
        load: fetchMessage,
    }));

    const fetchData = async () => {
        try {
            const response = await axios.post(backendlink + 'doctor/getpatient', {
                doctortoken: user.userToken,
                patienttoken: location.state.patientToken
            }, {
                headers: {
                    token: user.token
                }
            });

            if (response.data.errorno == 0) {
                const outputArray = response.data.result.map(item => {
                    setLinkedCustomerName(item.linkedcustomer);
                    setLinkedCustomerID(item.linkedcustomerid);
                    setLinkedDoctorName(item.linkeddoctor);
                    setPatientName(item.name);
                    fetchMessage({ skip: 0, take: pageSize });
                });
            } else {
                alert(response.data.errmessage);
            }
        } catch (error) {
            alert(error);
        }
    };

    const DataRow = (rowInfo) => (
        <React.Fragment>
            <tr role="row">
                <td role="gridcell">{rowInfo.data.date}</td>
                <td role="gridcell">{rowInfo.data.time}</td>
                <td role="gridcell">{rowInfo.data.sBP}</td>
                <td role="gridcell">{rowInfo.data.dBP}</td>
                <td role="gridcell">{rowInfo.data.bpm}</td>
            </tr>
        </React.Fragment>
    );

    useEffect(() => {
        if (location.state?.patientToken == null) {
            navigate('/');
        } else {
            setPatientToken(location.state.patientToken);
            fetchData();
        }
    }, [user]);

    return (
        <React.Fragment>
            <table className="custom-table">
                <thead>
                    <tr>
                        <th>Organization:</th>
                        <td>{linkedCustomerName}</td>
                        <th>Organization ID:</th>
                        <td>{linkedCustomerID}</td>
                        <th>Doctor:</th>
                        <td>{linkedDoctorName}</td>
                        <th>Patient:</th>
                        <td>{patientName}</td>
                    </tr>
                </thead>
            </table>

            <DataGrid
                dataSource={dataSource}
                dataRowRender={DataRow}
                remoteOperations={true}
            >
                <Column dataField="date" caption='Date' width="20%" />
                <Column dataField="time" caption='Time' width="20%" />
                <Column dataField="sBP" caption="Systolic BP (mmHg)" width="20%" allowSorting={false} alignment='left' />
                <Column dataField="dBP" caption="Diastolic BP (mmHg)" width="20%" allowSorting={false} alignment='left' />
                <Column dataField="bpm" caption="Pulse (BPM)" width="20%" allowSorting={false} alignment='left' />
                <Pager allowedPageSizes={[5, 10]} showPageSizeSelector={true} />
                <Paging
                    defaultPageSize={pageSize}
                    defaultPageIndex={pageIndex}
                />
                <Lookup />
            </DataGrid>
        </React.Fragment>
    );
}